<app-topnav></app-topnav>

<form [formGroup]="blogForm" (ngSubmit)="onSubmit()">
    <div class="top-main-wrapper">
        <div class="dash-header">
            <div class="dash-title">

                <div class="p-field p-grid">
                    <div class="p-col-6 p-md-6">
                        <h3>{{pageTitle}}</h3>
                    </div>
                    <div class="p-col-6 p-md-6">

                        <button pButton pRipple icon="pi pi-save" label="salva"
                            class="p-button p-button-success p-mr-2 pull-right" [disabled]="!blogForm.valid"></button>

                        <button type="button" pButton pRipple icon="pi pi-chevron-left"
                            label=" indietro" class="p-button p-button-back pull-right  p-mr-2"
                            [routerLink]="['/admin/news/']"></button>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="main-inner">

        <div class="p-fluid">
            <div class="p-field p-grid">




                <div class="p-col-12 p-md-12">


                    <p-tabView>

                        <p-tabPanel header="Informazioni">
                            <div class="p-grid">

                                <div class="p-col-12 p-md-12">
                                    <p-card>
                                        <div class="p-grid">

                                            <div class="p-col-12 p-md-7">
                                                <div class="left-section-card">
                                                <div class="p-fluid">


                                                    <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">Nome <span
                                                                    class="required">*</span></label>
                                                            <div class="p-col-12 p-md-12">
                                                                <input type="text" pInputText placeholder="Nome"
                                                                    formControlName="page_title" />
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="p-fluid">

                                                        <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">Page url <span
                                                                    class="required">*</span></label>
                                                            <div class="p-col-12 p-md-12">
                                                                <input type="text" appSlugTransform pInputText placeholder="Nome"
                                                                    formControlName="page_url" />
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="p-fluid">
                                                        <div class="p-field p-grid">
                                                            <div class="p-col-12 p-mb-2 p-md-12">
                                                                <label class="p-mb-2">Descrizione breve <span
                                                                        class="required">*</span></label>
                                                            </div>
                                                            <div class="p-col-12 p-md-12">

                                                                <p-editor formControlName="description_1"
                                                                    [style]="{ height: '220px' }">
                                                                    <ng-template pTemplate="header">
                                                                        <span class="ql-formats">
                                                                            <button type="button" class="ql-bold"
                                                                                aria-label="Bold"></button>
                                                                            <button type="button" class="ql-italic"
                                                                                aria-label="Italic"></button>
                                                                            <button type="button" class="ql-underline"
                                                                                aria-label="Underline"></button>
                                                                                <button type="button" 
                                                                                class="ql-clean" 
                                                                                aria-label="clean"> 
                                                                            </button> 
                                                                       
                                                                            <button type="button" 
                                                                                class="ql-link" 
                                                                                aria-label="Link"> 
                                                                            </button>
                                                                        </span>
                                                                    </ng-template>
                                                                </p-editor>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="p-fluid">
                                                        <div class="p-field p-grid">
                                                            <div class="p-col-12 p-mb-2 p-md-12">
                                                                <label class="p-mb-2">Descrizione breve <span
                                                                        class="required">*</span></label>
                                                            </div>
                                                            <div class="p-col-12 p-md-12">

                                                                <p-editor formControlName="description_2"
                                                                    [style]="{ height: '220px' }">
                                                                    <ng-template pTemplate="header">
                                                                        <span class="ql-formats">
                                                                            <button type="button" class="ql-bold"
                                                                                aria-label="Bold"></button>
                                                                            <button type="button" class="ql-italic"
                                                                                aria-label="Italic"></button>
                                                                            <button type="button" class="ql-underline"
                                                                                aria-label="Underline"></button>
                                                                                <button type="button" 
                                                                                class="ql-clean" 
                                                                                aria-label="clean"> 
                                                                            </button> 
                                                                       
                                                                            <button type="button" 
                                                                                class="ql-link" 
                                                                                aria-label="Link"> 
                                                                            </button>
                                                                        </span>
                                                                    </ng-template>
                                                                </p-editor>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                                <div class="p-col-12 p-md-5">
                                                    <div class="p-grid">

                                                    <label class="p-col-12 p-mb-2">Opzioni</label>
                                                    </div>
                                                    <div class="well">
                                                    <div class="p-fluid">
                                                        <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">Attivo <span
                                                                    class="required">*</span></label>
                                                            <div class="p-col-12 p-md-12">

                                                                <p-selectButton [options]="stateOptions"
                                                                    formControlName="is_active" optionLabel="label"
                                                                    optionValue="value"></p-selectButton>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        

                                    </p-card>
                                </div>
                            </div>

                            <div class="form-group form-row">
                                <label class="col-md-3"></label>
                                <div class="col-md-9">
                                    <input type="hidden" formControlName="id">
                                </div>
                            </div>
                        </p-tabPanel>


                        <p-tabPanel header="Seo">

                            <div class="p-grid">

                                <div class="p-col-12 p-md-12">
                                    <p-card>
                                        <div class="p-grid">
                                            <div class="p-col-12 p-md-7">
                                                <div class="left-section-card">
                                                    <div class="p-fluid">


                                                        <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">SEO title<span
                                                                    class="required">*</span></label>
                                                            <div class="p-col-12 p-md-12">

                                                                <input type="text" [style]="{ width: '100%' }"
                                                                    pInputText formControlName="page_seotitle">

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="p-fluid">


                                                        <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">SEO title<span
                                                                    class="required">*</span></label>
                                                            <div class="p-col-12 p-md-12">

                                                                <input type="text" [style]="{ width: '100%' }"
                                                                    pInputText formControlName="page_seokeywords">

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="p-fluid">


                                                        <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">SEO title<span
                                                                    class="required">*</span></label>
                                                            <div class="p-col-12 p-md-12">

                                                                <input type="text" [style]="{ width: '100%' }"
                                                                    pInputText formControlName="page_seodescription">

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </p-card>
                                </div>
                            </div>
                        </p-tabPanel>

                        <p-tabPanel header="Slideshow" *ngIf="element">


                            <app-upload [element]="element"></app-upload>



                        </p-tabPanel>


                        <p-tabPanel header="Documenti" *ngIf="element">


                            <app-upload-files [element]="element"></app-upload-files>



                        </p-tabPanel>


                    </p-tabView>

    

                </div>
            </div>
        </div>
    </div>
</form>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" #cd>

    <p-footer>
        <button type="button" pButton icon="fa fa-close" class="p-button p-button-danger p-mr-2" label="No"
            (click)="cd.reject()"></button>
        <button type="button" pButton icon="fa fa-check" class="p-button p-button-success p-mr-2" label="Si"
            (click)="cd.accept()"></button>
    </p-footer>

</p-confirmDialog>

<p-toast key="myKey1"></p-toast>