<app-topnav></app-topnav>

<form [formGroup]="blogForm" (ngSubmit)="onSubmit()">
    <div class="top-main-wrapper">
        <div class="dash-header">
            <div class="dash-title">

                <div class="p-field p-grid">
                    <div class="p-col-6 p-md-6">
                        <h3>{{pageTitle}}</h3>
                    </div>
                    <div class="p-col-6 p-md-6">

                        <button pButton pRipple icon="pi pi-save" label="salva"
                            class="p-button p-button-success p-mr-2 pull-right" [disabled]="!blogForm.valid"></button>

                        <button type="button" pButton pRipple icon="pi pi-chevron-left"
                            label=" indietro" class="p-button p-button-back pull-right  p-mr-2"
                            [routerLink]="['/admin/users/']"></button>
                    </div>
                </div>

            </div>
        </div>
    </div>

    
    <div class="main-inner">

        <div class="p-fluid">
            <div class="p-field p-grid">
                <div class="p-col-12 p-md-12">

                    <p-tabView class="responsive-tab-view">
                        <p-tabPanel header="Informazioni">

                            <p-card>
                                <div class="p-grid">
                                    <div class="p-col-12 p-md-7">
                                        <div class="left-section-card">
                                            <div class="p-fluid">
                

                                        <div class="p-field p-grid">
                                            <label class="p-col-12 p-mb-2 p-md-12 p-mb-md-0">Username <span
                                                    class="required">*</span></label>
                                            <div class="p-col-12 p-md-12">
                                                <input type="text" pInputText placeholder="Username"
                                                    formControlName="username" />
                                            </div>

                                        </div>
                                    </div>





                                    <div class="p-fluid">

                                        <div class="p-field p-grid">
                                            <label class="p-col-12 p-mb-2 p-md-12 p-mb-md-0">Nome <span
                                                    class="required">*</span></label>
                                            <div class="p-col-12 p-md-12">
                                                <input type="text" pInputText formControlName="first_name" />

                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-fluid">

                                        <div class="p-field p-grid">
                                            <label class="p-col-12 p-mb-2 p-md-12 p-mb-md-0">Cognome <span
                                                    class="required">*</span></label>
                                            <div class="p-col-12 p-md-12">
                                                <input type="text" pInputText formControlName="last_name" />

                                            </div>
                                        </div>
                                    </div>

                                    <div class="p-fluid">

                                        <div class="p-field p-grid">
                                            <label class="p-col-12 p-mb-2 p-md-12 p-mb-md-0">Password <span
                                                    class="required">*</span></label>
                                            <div class="p-col-12 p-md-12">
                                                <input type="text" pInputText formControlName="password" />

                                            </div>
                                        </div>
                                    </div>

                                
                                    <div class="p-fluid">

                                        <div class="p-field p-grid">
                                            <label class="p-col-12 p-mb-2 p-md-12 p-mb-md-0">Email <span
                                                    class="required">*</span></label>
                                            <div class="p-col-12 p-md-12">
                                                <input type="text" pInputText formControlName="email" />

                                            </div>
                                        </div>
                                    </div>




                                </div>

                                    </div>



                <div class="p-col-12 p-md-5">
                    <div class="p-field p-grid">

                        <div class="p-col-12">
                    <div class="well">

                                <div class="p-fluid">
                                    <div class="p-field p-grid">
                                        <label class="p-col-12 p-mb-2 p-md-12 p-mb-md-0">Attivo <span
                                                class="required">*</span></label>
                                        <div class="p-col-12 p-md-12">

                                            <p-selectButton [options]="stateOptions" formControlName="is_active"
                                                optionLabel="label" optionValue="value"></p-selectButton>
                                        </div>
                                    </div>
                                </div>



                                <input type="hidden" formControlName="id">

                    </div>
                        </div>

                    </div>
                </div>
                                </div>
                            </p-card>
                        </p-tabPanel>


                    </p-tabView>
                  
                </div>

                <div class="p-col-12 p-md-4">
                 
                </div>
            </div>
        </div>
    </div>
</form>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" #cd>

    <p-footer>
        <button type="button" pButton icon="fa fa-close" class="p-button p-button-danger p-mr-2" label="No"
            (click)="cd.reject()"></button>
        <button type="button" pButton icon="fa fa-check" class="p-button p-button-success p-mr-2" label="Si"
            (click)="cd.accept()"></button>
    </p-footer>

</p-confirmDialog>

<p-toast key="myKey1"></p-toast>