<p-sidebar [(visible)]="filterSidebar" #op position="right">
    <ng-template pTemplate>
        <div class="p-col-12">
            <div class="p-field p-grid">
                <div class="p-col-12">
    
                    <input type="text" [style]="{ width: '100%' }" pInputText [(ngModel)]="nameFilter" placeholder="Nome" />
                </div>
            </div>
            <div class="p-field p-grid">
                <div class="p-col-12">
                    <input type="text" [style]="{ width: '100%' }" pInputText [(ngModel)]="descriptionFilter"
                        placeholder="Cognome" />
                </div>
            </div>
    
          
            <div class="p-field p-grid">
                <div class="p-col-6">
                    <button pButton type="reset" class="p-button p-button-secondary btn-block"
                    label="annulla" (click)="reset()">
                </button>
                </div>
    
                <div class="p-col-6">
                    <button pButton type="submit" class="p-button p-button-primary btn-block"
                    label="cerca" (click)="load()">
                </button>
                </div>
    
            </div>
        </div>
        
    </ng-template>
</p-sidebar>

<app-topnav></app-topnav>

<div class="top-main-wrapper">


    <div class="dash-header">
        <div class="dash-title">
    
            <div class="p-field p-grid">
                <div class="p-col-6 p-md-6">
                    <h3>News</h3>
                </div>
                <div class="p-col-6 p-md-6">
    
                    <button pButton pRipple icon="pi pi-plus" [routerLink]="['/admin/news/create']" label=" aggiungi" class="p-button p-button-primary -mr-2  pull-right"></button>
                    <!-- <button type="button" pButton pRipple icon="pi pi-file-pdf" labe l="Esporta" (click)="exportPdf()" class="p-button-options p-mr-2 pull-right"  tooltipPosition="bottom"></button> -->
                    <button type="text" pButton label="Filter"  icon="pi pi-filter" class="p-button p-button-options p-mr-2 pull-right" (click)="filterSidebar = true"></button>
      
                </div>
            </div>
    
        </div>
    </div>
</div>



<div class="main-inner">





<div class="p-fluid">
    <div class="p-field p-grid">
        <div class="p-col-12 p-md-12">

                <div class="filter-options" *ngIf="nameFilter || descriptionFilter">

                    <div class="p-field p-grid">

                        <div class="p-col-12 p-md-8">
                            <div class="item-size pull-left">
                                <span class="filter-tag" *ngIf="nameFilter" >Nome: {{nameFilter}} <span class="close"><i class="pi pi-times"  (click)="reset()"></i></span> </span>
                                <span class="filter-tag" *ngIf="descriptionFilter">Cognome: {{descriptionFilter}} <span class="close"><i class="pi pi-times" (click)="reset()"></i></span> </span>
                            
                            </div>
                        </div>
                    </div>
                </div>

                    <div *ngFor=" let news of newss  | paginate: { itemsPerPage: pageSize, currentPage: page, totalItems: count }; let i = index"></div>

                <p-table #dt [columns]="cols" [value]="newss"   [responsive]="true"  [autoLayout]="true"  [paginator]="false" [rows]="10">
    
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                     
                            <th *ngFor="let col of cols">
                                {{col.header}} 
                            </th>
                            <th width="200"></th>
                        </tr>
                       
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr>
                            <td *ngFor="let col of cols">

                                <div *ngIf="col.field == 'page_title'">{{ rowData[col.field] }}</div>

                                <div *ngIf="col.field == 'is_active'">

                                    <span *ngIf="rowData[col.field] == 1"><p-tag severity="success" value="SI"></p-tag>
                                    </span>
                                    <span *ngIf="rowData[col.field] == 0"><p-tag severity="danger"  value="NO"></p-tag></span>
                                   </div>
                            </td>
                       
                
                            <td class="action">
                                <button pButton pRipple icon="pi pi-pencil" class="p-button p-button-success pull-right p-mr-2"
                                [routerLink]="['/admin/news/edit', rowData.id]"></button>
                            <button pButton pRipple icon="pi pi-eye" class="p-button p-button-primary pull-right p-mr-2"
                                (click)="edit(rowData)"></button>
                                              
                            </td>
                        </tr>
                    </ng-template>
                </p-table> 

                <div class="p-grid">
                    <div class="p-col-12 p-md-4">
                        <p class="p-pt-1">
                            <span class="count-item pull-left">{{pageSize * page - pageSize +1 }} - {{pageSize * page}} di {{count}} risultati</span></p></div>
                    <div class="p-col-12 p-md-8">
                        <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true"
                            (pageChange)="handlePageChange($event)">
                        </pagination-controls>
                    </div>
                </div>
              
        </div>

       
    </div>
</div>

</div>
<p-sidebar [(visible)]="productDialog" position="right"  [style]="{width:'50%'}">
    <ng-template pTemplate="content">
        <div class="p-col-12 p-md-12">

            <h1>{{news.page_title}}</h1>
          

            <p [innerHtml]="news.description_1"></p>
            <hr class="full">
            <p [innerHtml]="news.description_2"></p>
            <hr class="full">

            <div class="clearfix"></div><br>
            <button pButton pRipple icon="pi pi-pencil" label="Modifica" class="p-button p-button-primary p-mr-2" [routerLink]="['/admin/news/edit', news.id]"></button>
   
   </div>
    </ng-template>

</p-sidebar>




<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" #cd>

    <p-footer>
        <button type="button" pButton icon="fa fa-close" class="p-button p-button-danger p-mr-2" label="No" (click)="cd.reject()"></button>
        <button type="button" pButton icon="fa fa-check" class="p-button p-button-success p-mr-2" label="Si" (click)="cd.accept()"></button>
    </p-footer>

</p-confirmDialog>

<p-toast key="myKey1"></p-toast>

<p-toast key="myKey2">{{error}}</p-toast>



