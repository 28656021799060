<app-topnav></app-topnav>

<div class="top-main-wrapper">

    <div class="dash-header">
        <div class="dash-title">

            <div class="p-field p-grid">
                <div class="p-col-6 p-md-6">
                    <h3>Ciao, {{currentUser.first_name}} {{currentUser.last_name}}</h3>
                </div>
                <div class="p-col-6 p-md-6">

                </div>
            </div>

        </div>
    </div>
</div>


<div class="main-inner">

    <div class="p-grid">

    </div>

    <div class="p-grid">
        <div class="p-col-12 p-md-12">
            <div class="p-grid">

                <div class="p-col-12 p-md-3 p-lg-3">

                    <p-card class="featured-card cp" [routerLink]="['/admin/animations']">

                        <div class="p-grid">

                            <div class="p-col-12 p-md-12" *ngIf="animations">
                                <h4 class="mb-0 p-mb-0">Animazione</h4>

                                <span class="featured-card-number"> {{countAnimations}}</span>
                            </div>


                        </div>
                    </p-card>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3">

                    <p-card class="featured-card cp" [routerLink]="['/admin/news']">

                        <div class="p-grid">

                            <div class="p-col-12 p-md-12" *ngIf="news">
                                <h4 class="mb-0 p-mb-0">News</h4>
                                <span class="featured-card-number"> {{countNews}}</span>

                            </div>


                        </div>
                    </p-card>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3">

                    <p-card class="featured-card cp" [routerLink]="['/admin/clients']">

                        <div class="p-grid">

                            <div class="p-col-12 p-md-12" *ngIf="clients">
                                <h4 class="mb-0 p-mb-0">Clienti</h4>
                                <span class="featured-card-number">{{countClients}}</span>

                            </div>


                        </div>
                    </p-card>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3">

                    <p-card class="featured-card cp" [routerLink]="['/admin/clients-works']">

                        <div class="p-grid">

                            <div class="p-col-12 p-md-12" *ngIf="clientsWorks">
                                <h4 class="mb-0 p-mb-0">Lavori Clienti</h4>
                                <span class="featured-card-number">{{countClientsWorks}}</span>

                            </div>


                        </div>
                    </p-card>
                </div>
            </div>
        </div>
    </div>
    <div class="p-grid">

        <div class="p-col-12 p-md-6">
            <h3>Ultime Proposte</h3>

            <div class="p-grid">

                <div class="p-col-12 p-md-12">

             

                        <p-table #dt [columns]="cols" [value]="animations" [rows]="10" [responsive]="false"
                        [autoLayout]="true" [paginator]="false">


                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr>
                                <td *ngFor="let col of cols">

                                    <div *ngIf="col.field == 'page_title'">{{ rowData[col.field] | slice: 0: 50 }} ...</div>
                                 
                                </td>

                                <td class="action">
                                 
                                    <button pButton pRipple icon="pi pi-pencil"
                                        class="p-button p-button-success pull-right  p-mr-2"
                                        [routerLink]="['/admin/animations/edit', rowData.id]"></button>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                 

                </div>
            </div>
        </div>


        <div class="p-col-12 p-md-6">
            <h3>Ultime News</h3>

            <div class="p-grid">

                <div class="p-col-12 p-md-12">



                    <p-table #dt [columns]="cols" [value]="news" [rows]="10" [responsive]="false" [autoLayout]="true"
                        [paginator]="false">


                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr>
                                <td *ngFor="let col of cols">

                                    <div *ngIf="col.field == 'page_title'">{{ rowData[col.field] | slice: 0: 50 }} ...
                                    </div>

                                </td>

                                <td class="action">

                                    <button pButton pRipple icon="pi pi-pencil"
                                        class="p-button p-button-success pull-right  p-mr-2"
                                        [routerLink]="['/admin/news/edit', rowData.id]"></button>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>


                </div>
            </div>
        </div>


    </div>
</div>