<app-topnav></app-topnav>

<form [formGroup]="blogForm">

    <div class="top-main-wrapper">

        <div class="dash-header">
            <div class="dash-title">

                <div class="p-field p-grid">
                    <div class="p-col-8 p-md-6">
                        <h3>Dettaglio Animazione</h3>
                    </div>
                    <div class="p-col-4 p-md-6">
                        <button pButton pRipple icon="pi pi-save" label="salva"
                            class="p-button pull-right p-button-success p-mr-2" (click)="onSubmit()"
                            [disabled]="!blogForm.valid"></button>


                        <button type="button" pButton pRipple icon="pi pi-chevron-left"
                            label=" indietro" class="p-button p-button-back pull-right  p-mr-2"
                            [routerLink]="['/admin/animations/']"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="main-inner">

        <div class="p-fluid">
            <div class="p-field p-grid">




                <div class="p-col-12 p-md-12">


                    <p-tabView>

                        <p-tabPanel header="Informazioni">
                            <div class="p-grid">

                                <div class="p-col-12 p-md-12">
                                    <p-card>
                                        <div class="p-grid">
                                            <div class="p-col-12 p-md-7">
                                                <div class="left-section-card">
                                                    <div class="p-fluid">


                                                        <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">Nome<span
                                                                    class="required">*</span></label>
                                                            <div class="p-col-12 p-md-12">

                                                                <input type="text" [style]="{ width: '100%' }"
                                                                    pInputText formControlName="page_title">

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="p-fluid">


                                                        <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">Seo Url<span
                                                                    class="required">*</span></label>
                                                            <div class="p-col-12 p-md-12">
                                                                <input type="text" appSlugTransform [style]="{ width: '100%' }"
                                                                    (change)="onChange($event)" pInputText
                                                                    formControlName="page_url">
                                                                <div
                                                                    *ngIf="blogForm.get('page_url').hasError('alreadyExists')">
                                                                    Questa URL esiste gia</div>

                                                            </div>
                                                        </div>
                                                    </div>




                                                    <div class="p-fluid">
                                                        <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">Descrizione 1</label>
                                                            <div class="p-col-12 p-md-12">
                                                                <p-editor formControlName="description_1"
                                                                    [style]="{ height: '220px' }">
                                                                    <ng-template pTemplate="header">
                                                                        <span class="ql-formats">
                                                                            <button type="button" class="ql-bold"
                                                                                aria-label="Bold"></button>
                                                                            <button type="button" class="ql-italic"
                                                                                aria-label="Italic"></button>
                                                                            <button type="button" class="ql-underline"
                                                                                aria-label="Underline"></button>
                                                                                <button type="button" class="ql-clean"
                                                                                aria-label="clean"></button>
                                                                        </span>
                                                                    </ng-template>
                                                                </p-editor>



                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="p-fluid">
                                                        <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">Descrizione 2</label>
                                                            <div class="p-col-12 p-md-12">
                                                                <p-editor formControlName="description_2"
                                                                    [style]="{ height: '220px' }">
                                                                    <ng-template pTemplate="header">
                                                                        <span class="ql-formats">
                                                                            <button type="button" class="ql-bold"
                                                                                aria-label="Bold"></button>
                                                                            <button type="button" class="ql-italic"
                                                                                aria-label="Italic"></button>
                                                                            <button type="button" class="ql-underline"
                                                                                aria-label="Underline"></button>
                                                                                <button type="button" class="ql-clean"
                                                                                aria-label="clean"></button>
                                                                        </span>
                                                                    </ng-template>
                                                                </p-editor>



                                                            </div>
                                                        </div>
                                                    </div>



                                                    <div class="p-field p-grid">
                                                        <div class="p-col-12">
                                                            <input type="hidden" formControlName="id">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12 p-md-5">
                                                <div class="well">

                                                    <div class="p-fluid">
                                                        <div class="p-grid">
                                                            <div class="p-col-8">
                                                                <div class="p-grid">

                                                                    <label class="p-col-12 p-mb-2">In evidenzia <span
                                                                            class="required">*</span></label>
                                                                    <div class="p-col-12 p-md-12">
                                                                        <p-dropdown [options]="statePublished"
                                                                            formControlName="status"
                                                                            [(ngModel)]="selectedStatePublished"
                                                                            optionLabel="label" optionValue="value">

                                                                        </p-dropdown>

                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div class="p-col-4">
                                                                <div class="p-grid">

                                                                    <label class="p-col-12 p-mb-2">Attivo <span
                                                                            class="required">*</span></label>
                                                                    <div class="p-col-12 p-md-12">
                                                                        <p-selectButton [options]="stateOptions"
                                                                            formControlName="is_active"
                                                                            optionLabel="label"
                                                                            optionValue="value"></p-selectButton>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <hr><br>
                                                    <div class="p-fluid">
                                                        <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">A cura di</label>
                                                            <div class="p-col-12 p-md-12">
                                                                <p-multiSelect [options]="featuresPersons"
                                                                    display="chip" formControlName="person_id"
                                                                    [(ngModel)]="selectedPersons" optionLabel="name"
                                                                    optionValue="id">
                                                                </p-multiSelect>


                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="p-fluid">
                                                        <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">Destinazione</label>
                                                            <div class="p-col-12 p-md-12">
                                                                <p-multiSelect  (onPanelHide)="panelClosed = true" [options]="featuresDestinations"
                                                                    display="chip" formControlName="destination_id"
                                                                    [(ngModel)]="selectedDestinations"
                                                                    optionLabel="name" optionValue="id">

                                                                </p-multiSelect>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="p-fluid">
                                                        <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">Tipologia</label>
                                                            <div class="p-col-12 p-md-12">
                                                                <p-multiSelect [options]="featuresTypology"
                                                                    display="chip" formControlName="typology_id"
                                                                    [(ngModel)]="selectedTypology" optionLabel="name"
                                                                    optionValue="id">

                                                                </p-multiSelect>

                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="p-fluid">
                                                        <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">Area</label>
                                                            <div class="p-col-12 p-md-12">
                                                                <p-multiSelect [options]="featuresArea" display="chip"
                                                                    formControlName="area_id" [(ngModel)]="selectedArea"
                                                                    optionLabel="name" optionValue="id">

                                                                </p-multiSelect>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>



                                            </div>

                                        </div>

                                    </p-card>
                                </div>
                            </div>

                        </p-tabPanel>

                        <p-tabPanel header="Dati aggiuntivi">



                            <p-card>


                                <div class="p-grid">
                                    <div class="p-col-12 p-md-7">
                                        <div class="left-section-card">
                                            <div class="p-fluid">



                                              
                                                <table formArrayName="skills" style="width: 100%">

                                                    <tr *ngFor="let quantity of skills.controls; let i=index"
                                                        [formGroupName]="i">
                                                        <td width="100%">

                                                            <p-dropdown [options]="featuresTechnicalData"
                                                                [showClear]="true"
                                                                placeholder="Scegli" [style]="{ width: '100%' }"
                                                                formControlName="label" optionLabel="name"
                                                                optionValue="id">
                                                                <ng-template let-option pTemplate="item">

                                                                    {{option.name}}

                                                                </ng-template>

                                                            </p-dropdown>

                                                            <input type="text" placeholder="Valore" class="p-mt-2"
                                                                [style]="{ width: '100%' }" pInputText
                                                                formControlName="value">

                                                            <button pButton (click)="removeQuantity(i)"
                                                                icon="pi pi-trash"
                                                                class="p-button p-button-danger pull-left p-mt-2"></button>

                                                        </td>
                                                    </tr>
                                                </table>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="p-col-12 p-md-5">
                                        <div class="p-field p-grid">

                                            <div class="p-col-12">
                                        <div class="well">
                                            <div class="p-field p-grid">

                                                <div class="p-col-12">

                                                    <button pButton pRipple icon="pi pi-plus"
                                                        label="Dati aggiuntivi"
                                                        class="p-button pull-left p-button-success p-mr-2"
                                                        (click)="addQuantity()"></button>

                                                </div>
                                            </div>
                                        </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </p-card>
                        </p-tabPanel>

                        <p-tabPanel header="Seo">

                            <div class="p-grid">

                                <div class="p-col-12 p-md-12">
                                    <p-card>
                                        <div class="p-grid">
                                            <div class="p-col-12 p-md-7">
                                                <div class="left-section-card">
                                                    <div class="p-fluid">


                                                        <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">SEO title<span
                                                                    class="required">*</span></label>
                                                            <div class="p-col-12 p-md-12">

                                                                <input type="text" [style]="{ width: '100%' }"
                                                                    pInputText formControlName="page_seotitle">

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="p-fluid">


                                                        <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">SEO title<span
                                                                    class="required">*</span></label>
                                                            <div class="p-col-12 p-md-12">

                                                                <input type="text" [style]="{ width: '100%' }"
                                                                    pInputText formControlName="page_seokeywords">

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="p-fluid">


                                                        <div class="p-field p-grid">
                                                            <label class="p-col-12 p-mb-2">SEO title<span
                                                                    class="required">*</span></label>
                                                            <div class="p-col-12 p-md-12">

                                                                <input type="text" [style]="{ width: '100%' }"
                                                                    pInputText formControlName="page_seodescription">

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </p-card>
                                </div>
                            </div>
                        </p-tabPanel>
                        <p-tabPanel header="Slideshow" *ngIf="element">


                            <app-upload [element]="element"></app-upload>



                        </p-tabPanel>


                        <p-tabPanel header="Documenti" *ngIf="element">


                            <app-upload-files [element]="element"></app-upload-files>



                        </p-tabPanel>

                    </p-tabView>



                </div>



            </div>

        </div>
    </div>
</form>

<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" #cd>

    <p-footer>
        <button type="button" pButton icon="fa fa-close" class="p-button p-button-danger p-mr-2" label="No"
            (click)="cd.reject()"></button>
        <button type="button" pButton icon="fa fa-check" class="p-button p-button-success p-mr-2" label="Si"
            (click)="cd.accept()"></button>
    </p-footer>

</p-confirmDialog>

<p-toast key="myKey1" position="top-right"></p-toast>