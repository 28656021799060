import { Component, ElementRef, OnInit } from "@angular/core";
import { AppointmentsService } from "../../../services/appointments.service";
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
  UntypedFormArray,
  FormGroup,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ViewChild } from "@angular/core";
import { Blog } from "../../../models/blog";
import { Category } from "../../../models/category";
import { UntypedFormControl } from "@angular/forms";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import * as moment from "moment";
import {
  TYPE_LIST,
  STATUS_PRODUCTS,
  STATE_LIST,
  STATE_PUBLISHED,
} from "../../constants/constants";
import { Clients } from "src/app/models/clients";
import { ClientsService } from "src/app/services/clients.service";
import { Location } from "@angular/common";
import { Works } from "src/app/models/works";
import { Locations } from "src/app/models/locations";
import { map, tap } from "rxjs/operators";
import { Technical_data } from "src/app/models/technical_data";
import { TechnicalDataService } from "src/app/services/technical_data.service";
import { Observable } from "rxjs";
import { HttpEventType, HttpResponse } from "@angular/common/http";
import { AnimationsService } from "src/app/services/animations.service";
import { SlideshowService } from "src/app/services/slideshow.service";
import { DocumentsService } from "src/app/services/documents.service";
import { Slideshow } from "src/app/models/slideshow";
import { ValidateSeoUrl } from "../../pipe/validator.pipe";
import { SeoService } from "src/app/services/seoservice";
import { CrudService } from "src/app/services/crud.service";
import { FeaturesPersonsService } from "src/app/services/features_persons.service";
import { FeaturesTypologysService } from "src/app/services/features_typologyy";
import { FeaturesAreaService } from "src/app/services/features_area";
import { FeaturesDestinationsService } from "src/app/services/features_destinations.service";
import { Animations } from "src/app/models/animations";
import { NgxSpinnerService } from "ngx-spinner";
import { FeaturesTechnicalDataService } from "src/app/services/features_technical_data";

export interface fPairs {
  label: number;
  value: number;
}

@Component({
  selector: "app-animations-form",
  templateUrl: "./animations-form.component.html",
})
export class AnimationsFormComponent implements OnInit {
  @ViewChild("myInput", { static: false }) myInputVariable: ElementRef;
  pageTitle: string;
  error: string;
  uploadError: string;
  imagePath: any;
  blogs: Blog;
  blog: Blog;
  id: number;
  
  animation: any;

  category: Category;
  works: any = [];
  work: Works;

  checked: boolean = true;
  selectedValue: string;

  blogForm: FormGroup;
  typeList: any[];


  statePublished: any[];
  stateOptions: any[];

  clients: any = [];
  client: Clients;
  technical_datas: any = [];
  technical_data: Technical_data;

  tags: any = [];
  selectedFiles?: FileList;
  progressInfos: any[] = [];
  message: string[] = [];

  previews: string[] = [];
  imageInfos?: Observable<any>;

  description: any;
  selectedFeartures: SelectItem[] = [];
  selectedTypology: SelectItem[] = [];
  selectedFeatures: SelectItem[] = [];
  selectedPersons: SelectItem[] = [];
  selectedArea: SelectItem[] = [];
  selectedStatePublished: SelectItem[] = [];

  selectedDestinations: SelectItem[] = [];

  featuresPersons: Category[] = [];
  featuresTypology: Category[] = [];
  featuresArea: Category[] = [];
  featuresTechnicalData: Category[] = [];
  featuresDestinations: Category[] = [];

  locations: any = [];
  location: Locations;

  cities: Blog[];
  selectedCity: Blog;
  selectedClients: SelectItem[];
  panelClosed = false;

  selectedDate: Date;
  date: Date;
  works_id: any;
  category_id: any;
  public dataValues: object;
  pages: any;
  currentUser: any;
  fPairs: any;
  addForm: UntypedFormGroup;
  rows: UntypedFormArray;
  itemForm: UntypedFormGroup;
  skillsForm: UntypedFormGroup;
  skillsValues: any = [];
  cols: any[];
  colsData: any[];
  productTitle: string;
  trackByFn(index, item) {
    return item.id;
  }
  element: Animations;
  slideshows: any = [];
  slideshow: Slideshow;
  documents: any = [];
  selectedItemDisplay: string;
  
  constructor(
    private fb: UntypedFormBuilder,
    private messageService: MessageService,
    private _location: Location,
    private animationsService: AnimationsService,
    private featuresPersonsService: FeaturesPersonsService,
    private featuresTypologyService: FeaturesTypologysService,
    private featuresAreaService: FeaturesAreaService,
    private featuresDestinationsService: FeaturesDestinationsService,
    private featuresTechnicalDataService: FeaturesTechnicalDataService,
    private spinner: NgxSpinnerService,
    private seoService: SeoService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private slideshowService: SlideshowService,
    private documentService: DocumentsService,
    private route: ActivatedRoute
  ) {
    if (this.date) {
      this.selectedDate = new Date(this.date);
    }
    this.typeList = TYPE_LIST;
    this.statePublished = STATE_PUBLISHED;
    this.stateOptions = STATE_LIST;
  }

  ngOnInit() {

    this.spinner.show();

    const id = this.route.snapshot.paramMap.get("id");

    if (id) {
      this.pageTitle = "Modifica Prodotto";

      this.animationsService.getId(+id).subscribe((res) => {
        this.element = res
        this.imageInfos = this.slideshowService.getId(+id);

        this.blogForm.patchValue({
          page_title: res.page_title,
          description_1: res.description_1,
          description_2: res.description_2,
          page_url: res.page_url,
          is_active: res.is_active,
          status: res.status,
          person_id: res.person_id.split(","),
          area_id: res.area_id.split(","),
          destination_id: res.destination_id.split(","),
          typology_id: res.typology_id.split(","),
          id: res.id,
          skills: this.skillsValues,
        });

        this.imagePath = res.image;
        this.id = res.id;
      });
    } else {
      this.pageTitle = "Aggiungi Prodotto";
    }

    this.blogForm = this.fb.group({
      id: [""],
      page_title: ["", Validators.required],
      description_1: [""],
      description_2: [""],
      person_id: [""],
      area_id: [""],
      destination_id: [""],
      typology_id: [""],
      is_active: [""],
      status: [""],
      page_url: [
        "",
        Validators.compose([Validators.required, Validators.minLength(3)]),
      ],
    
      skills: this.initSkill(),


    });

    this.blogForm.get("page_title").valueChanges.subscribe(val => {
      this.blogForm.patchValue({ page_url: val });
    });

    this.slideshowService.getId(+id).subscribe((pData) => {
      this.slideshows = pData;
    });


    this.featuresPersonsService.getAllListNew({page: 0, size: 50}).subscribe((pData) => {
      this.featuresPersons = pData;
    });

    this.featuresTypologyService.getAllList().subscribe((pData) => {
      this.featuresTypology = pData;
    });

    this.featuresAreaService.getAllList().subscribe((pData) => {
      this.featuresArea = pData;
    });

    this.featuresTechnicalDataService.getAllList().subscribe((pData) => {
      this.featuresTechnicalData = pData;
    });

    

    this.featuresDestinationsService.getAllList().subscribe((pData) => {
      this.featuresDestinations = pData;
    });

    setTimeout(() => {

      this.spinner.hide();
  }, 200);

  }


  selectDivision(e) {
    this.selectedPersons = e.value.map((item) => item.name);
    if (this.selectedPersons.length > 3) {
      this.selectedItemDisplay = `Selected items: ${this.selectedPersons.join(', ')}`;
    } else {
      this.selectedItemDisplay = '';
    }
  }

  onChange(e) {
    const id = this.route.snapshot.paramMap.get("id");

    this.seoService
      .seoChecker(e.target.value, +id)
      .pipe(
        map((result: boolean) => (result ? { alreadyExists: true } : null))
      );
    console.log();
  }

  modelChange(e) {
    // Fired multiple time when the value is typed, need a ngmodel value for fired
    console.log("ModelChange Fired!");
    console.log(e);
    console.log("From ngModel", e.target.value);
  }


  initSkill() {
    var formArray = this.fb.array([]);
    const id = this.route.snapshot.paramMap.get("id");

    this.animationsService.skills(+id).subscribe(
      (res)=>{
        this.skillsValues = res;
        this.skillsValues.forEach((e)=>{
          formArray.push(this.fb.group({
            label: [e.label],
            value: [e.value],
          }))
        })
      }
    )

    /*formArray.push(this.fb.group({
      qty: [''],
      price: ['']
    })) */
    

    return formArray;
  }

 

  

  private createSkillFormGroup(skill: any): UntypedFormGroup {
    return new UntypedFormGroup({
      label: new UntypedFormControl(skill.label),
      value: new UntypedFormControl(skill.value),
    });
  }

  public addSkill(skill: any) {
    this.skills.push(this.createSkillFormGroup(skill));
  }

  get skills() {
    return this.blogForm.get("skills") as UntypedFormArray;
  }

  addQuantity() {
    this.skills.push(this.newQuantity());
  }

  removeQuantity(i: number) {
    this.skills.removeAt(i);
  }


  getselectedFeaturesPersons() {
    this.selectedFeatures = this.works_id.split(",");
  }

  goback() {
    this._location.back();
  }



  newQuantity(): UntypedFormGroup {
    return this.fb.group({
      label: "",
      value: "",
    });
  }


  onSubmit() {
    const formData = new FormData();
    formData.append("page_title", this.blogForm.get("page_title").value);
    formData.append("page_url", this.blogForm.get("page_url").value);
    formData.append("is_active", this.blogForm.get("is_active").value);
    formData.append("description_1", this.blogForm.get("description_1").value);
    formData.append("description_2", this.blogForm.get("description_2").value);
    formData.append("status", this.blogForm.get("status").value);
    formData.append("person_id", this.blogForm.get("person_id").value);
    formData.append("area_id", this.blogForm.get("area_id").value);
    formData.append("destination_id", this.blogForm.get("destination_id").value);
    formData.append("typology_id", this.blogForm.get("typology_id").value);
    //formData.append('skills', JSON.stringify(this.blogForm.get('skills').value));
    formData.append('skills', JSON.stringify(this.blogForm.get('skills').value));

    const id = this.blogForm.get("id").value;

    if (id) {
      this.animationsService.update(formData, +id).subscribe(
        (res) => {
          if (res.status == "error") {
            this.messageService.add({
              key: "myKey1",
              severity: "warn",
              summary: "Informazioni",
              detail: "Errore",
            });
            
            this.uploadError = res.message;
          } else {
            this.messageService.add({
              key: "myKey1",
              severity: "success",
              summary: "Informazioni",
              detail: "Salvato con sucesso",
            });
            //this.router.navigate(['/admin/products']);
          }
        },
        (error) => (this.error = error)
      );
    } else {
      this.animationsService.create(formData).subscribe(
        (res) => {
          if (res.status === "error") {
            this.uploadError = res.message;
          } else {
            this.messageService.add({
              key: "myKey1",
              severity: "success",
              summary: "Informazioni",
              detail: "Salvato con sucesso",
            });
            this.router.navigate(["/admin/animations"]);
          }
        },
        (error) => (this.error = error)
      );
    }
  }
}
