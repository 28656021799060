import { Component, ElementRef, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ViewChild } from '@angular/core';
import { Blog } from '../../../../models/blog';
import { Category } from '../../../../models/category';
import { FormControl } from '@angular/forms';

import { MessageService, SelectItem } from "primeng/api";
import * as moment from 'moment';
import { Comuni } from 'src/app/models/comuni';
import { STATE_LIST } from 'src/app/admin/constants/constants';
import { FeaturesAreaService } from 'src/app/services/features_area';
import { SectorService } from 'src/app/services/sector.service';

@Component({
  selector: 'app-features-sectors-form',
  templateUrl: './features-sectors-form.component.html'
})
export class FeaturesSectorsFormComponent implements OnInit {
  @ViewChild("myInput", { static: false }) myInputVariable: ElementRef;

  pageTitle: string;
  error: string;
  uploadError: string;
  imagePath: any;
  categories: Category;
  category: Category;

  comuni: Comuni;

  checked: boolean = true;
  selectedValue: string;

  categoryForm: UntypedFormGroup;

  cities: Blog[];
  format1: string = "";
  format2: string = "";
  selectedCity: Blog;
  selectedCategories: Category;
  selectedDate: Date;
  date: Date;
  currentUser: any;
  stateOptions: any[];

  constructor(
    private fb: UntypedFormBuilder,
    private featuresSectorService: SectorService, 
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    if (this.date) {
      this.selectedDate = new Date(this.date);
    }
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '[]');
  }

  ngOnInit() {


    const id = this.route.snapshot.paramMap.get('id');
    this.stateOptions = STATE_LIST;

    if (id) {


      this.pageTitle = 'Modifica Settori';
      this.featuresSectorService.getId(+id).subscribe(
        res => {
          this.categoryForm.patchValue({
            name: res.name,
            status: res.status,
            id: res.id
          });

        }
      );
    } else {
      this.pageTitle = 'Aggiungi';
    }

    this.categoryForm = this.fb.group({
      id: [''],
      name: ['', Validators.required],
      description: [''],
      status: ["0"],

    });
  }


  get category_name() {
    return this.categoryForm.get('name');
    return this.categoryForm.get('description');

  }


  onSubmit() {

    const formData = new FormData();

    formData.append('name', this.categoryForm.get('name').value);
    formData.append("status", this.categoryForm.get("status").value);

    const id = this.categoryForm.get('id').value;

    if (id) {
      this.featuresSectorService.update(formData, +id).subscribe(
        res => {
          if (res.status == 'error') {
            this.uploadError = res.message;
          } else {
            this.messageService.add({ key: 'myKey1', severity: 'success', summary: 'Attenzione', detail: 'Salvato con sucesso' });
            this.router.navigate(['/admin/settings/features-sectors']);
          }
        },
        error => this.error = error
      );
    } else {
      this.featuresSectorService.create(formData).subscribe(
        res => {
          if (res.status === 'error') {
            this.uploadError = res.message;
          } else {
            this.router.navigate(['/admin/settings/features-sectors']);
          }
        },
        error => this.error = error
      );
    }

  }

}
