import { Component, OnInit, ViewChild } from '@angular/core';
import { CategoryService } from '../../../../services/categories.service';
import { Category } from '../../../../models/category';
import { ConfirmationService } from 'primeng/api';
import { PARAM_CATEGORIES_PATH, PARAM_FEATURES_PERSONS_PATH, PARAM_FEATURES_TYPOLOGY_PATH, PARAM_TAGS_PATH } from 'src/app/admin/constants/constants';
import { Table } from 'primeng/table';
import { ActivatedRoute, Router } from '@angular/router';
import { FeaturesPersonsService } from 'src/app/services/features_persons.service';
import { FeaturesTypologysService } from 'src/app/services/features_typologyy';

@Component({
  selector: 'app-manage-features-typology',
  templateUrl: './manage-features-typology.component.html'
})
export class ManageFeaturesTypologyComponent implements OnInit {
  title = 'Animazione Tiplogia';

  error: string;

  categories: any = [];
  category: Category;
  displayFilter: boolean;
  public cols: any[];
  public columnOptions: any[];
  public selectedColumns: any[];
  nameFilter: string;
  descriptionFilter: string;
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [5, 10, 15];
  public base_path: string;
  basePath: string;
  pageOfItems: Array<any>;
  searchWrapper: boolean = false;
  @ViewChild('dt', { static: true }) dt: Table;
  currentUser: any;
  
  constructor(
    private featuresTypologyService: FeaturesTypologysService, 
    private router: Router,
    private route: ActivatedRoute,

    private confirmationService: ConfirmationService,) { 


    this.cols = [
      { field: 'name', header: 'Nome', index: 1 },
      { field: 'description', header: 'Descrizione', index: 2 }
    ];

    this.columnOptions = [];
    this.selectedColumns = [];
    for (let i = 0; i < this.cols.length; i++) {
      this.columnOptions.push({ label: this.cols[i].header, value: this.cols[i] });
    }

    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '[]');

  }

  ngOnInit() {


    this.basePath = window.location.pathname;
    if (this.route.snapshot.queryParamMap.has('page')) {
      this.page = +this.route.snapshot.queryParamMap.get('page');
    }
    if (this.route.snapshot.queryParamMap.has('size')) {
      this.pageSize = +this.route.snapshot.queryParamMap.get('size');
    }
    if (this.route.snapshot.queryParamMap.has('name')) {
      this.nameFilter = this.route.snapshot.queryParamMap.get('name');
    }
    if (this.route.snapshot.queryParamMap.has('description')) {
      this.descriptionFilter = this.route.snapshot.queryParamMap.get('description');
    }


    this.load();


  }




  getRequestParams(searchTitle, categoryTitle, page, pageSize): any {
    // tslint:disable-next-line:prefer-const
    let path = PARAM_FEATURES_TYPOLOGY_PATH;
    const params = {};
    let adder = '?';
    if (page) {
      params[`page`] = page - 1;
      path += adder + 'page=' + (page - 1);
      adder = '&';
    }
    if (searchTitle) {
      params[`name`] = searchTitle;
      path += adder + 'name=' + searchTitle;
      adder = '&';
    }
    if (categoryTitle) {
      params[`description`] = categoryTitle;
      path += adder + 'description=' + categoryTitle;
      adder = '&';
    }
    if (pageSize) {
      params[`size`] = pageSize;
      path += adder + 'size=' + pageSize;
    }
    window.history.replaceState({}, '', path);

    return params;

  }


  handlePageSizeChange(event): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.load();
  }

  reset(): void {
    this.nameFilter = '';
    this.descriptionFilter = '';
    this.load();

  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  public handlePageChange(event): void {
    this.page = event;
    this.load();

  }


  load(): void {

    const params = this.getRequestParams(
      this.nameFilter,
      this.descriptionFilter,
      this.page,
      this.pageSize
    );
    this.featuresTypologyService.getAllListNew(params).subscribe((pData) => {
      this.categories = pData;
      this.count = this.featuresTypologyService.size;

    });
  }   


  editProduct(category: Category) {
    this.category = { ...category };
  }


  onDelete(id: number, category_name: string) {

    this.confirmationService.confirm({
      message: 'Sei sicuro di volerlo cancellare = ' + category_name,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.featuresTypologyService.delete(+id).subscribe(
          res => {
            console.log(res);
            this.ngOnInit();
          },
          error => this.error = error
        );
      },

    });


  }

}
