<div class="top-main-wrapper">
    <div class="dash-header">
        <div class="dash-title">

            <div class="p-field p-grid">
                <div class="p-col-6 p-md-6">
                    <h3>Configurazioni</h3>
                </div>
                <div class="p-col-6 p-md-6">

                </div>
            </div>
        </div>
    </div>

</div>

<div class="main-inner">


    <div class="p-grid">


        <div class="p-col-12 p-md-12">

            <h3>Configurazione Clienti</h3>
        </div>

    </div>
    <div class="p-grid">

        <div class="p-col-12 p-md-4" *ngFor="let item of clientSettings">

            <p-card>


                <a routerLink="{{ item.value }}" routerLinkActive="active"><i class="{{ item.icon }}"></i> {{ item.label
                    }}</a>

            </p-card>
        </div>

    </div>

    <div class="p-grid">
        <div class="p-col-12 p-md-12">


            <h3>Configurazione Animazione</h3>
        </div>
    </div>
    <div class="p-grid">


            <div class="p-col-12 p-md-4" *ngFor="let item of animationsSettings">

                <p-card>


                    <a routerLink="{{ item.value }}" routerLinkActive="active"><i class="{{ item.icon }}"></i> {{
                        item.label
                        }}</a>

                </p-card>
            </div>
        </div>



    </div>