import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BlogService } from '../../services/blog.service';
import { Blog } from '../../models/blog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DASHBOARD, PAGES, TYPE_LIST } from '../constants/constants';
import { CategoryService } from 'src/app/services/categories.service';
import { Category } from 'src/app/models/category';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ClientsService } from 'src/app/services/clients.service';
import { Clients } from 'src/app/models/clients';
import { ComuniService } from 'src/app/services/comuni.service';
import { Comuni } from 'src/app/models/comuni';
import { Appointments } from 'src/app/models/appointments';
import { AppointmentsService } from 'src/app/services/appointments.service';
import * as $ from 'jquery';
import { formatDate } from '@angular/common';
import { Locations } from 'src/app/models/locations';
import { Employees } from 'src/app/models/employees';
import { WorksService } from 'src/app/services/works.service';
import { LocationsService } from 'src/app/services/locations.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { Works } from 'src/app/models/works';
import * as moment from 'moment';
import { ProductsService } from 'src/app/services/products.service';
import { Products } from 'src/app/models/products';
import { BillingsService } from 'src/app/services/billings.service';
import { Billings } from 'src/app/models/billings';
import { NgxSpinnerService } from "ngx-spinner";
import { ChartsService } from 'src/app/services/charts.service';
import { Charts } from 'src/app/models/charts';
import 'moment/locale/it'  // without this line it didn't work
import { SupportsService } from 'src/app/services/supports.service';
import { Supports } from 'src/app/models/supports';
import { ProjectsService } from 'src/app/services/projects.service';
import { Projects } from 'src/app/models/projects';

import { WarehouseCheckins } from 'src/app/models/warehouse_checkins';
import { AnimationsService } from 'src/app/services/animations.service';
import { NewsService } from 'src/app/services/news.service';
import { Animations } from 'src/app/models/animations';
import { News } from 'src/app/models/news';
import { ClientsWorksService } from 'src/app/services/clients_works.service';

moment.locale('it')

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html'
})
export class AdminDashboardComponent implements OnInit {


  calendarOptions: any;
  events: any;
  newss: any = [];
  appointment: Appointments;
  appointmentsToday:  any = [];
  locations: any = [];
  location: Locations;
  chart: [];

  employees: any = [];
  employee: Employees;
  warehouseCheckins: any = [];
  warehouseCheckin: WarehouseCheckins;
  works: any = [];
  work: Works;
  clientsCount: any;
  supports: any = [];
  support: Supports;

  products: any = [];
  product: Products;

  billings: any = [];
  billing: Billings;

  animations: Animations[] = [];
  news: News[] = [];

  productsCount: any;
  error: string;
  blogForm: UntypedFormGroup;
  typeList: any;
  cities: Blog[];
  format1: string = "";
  format2: string = "";
  selectedCity: Blog;
  uploadError: string;
  selectedCategories: Category;
  selectedDate: Date;
  date: Date;
  selectedWorks: any[];
  categories: any = [];
  category: Category;
  productDialog: boolean = false;
  appointmentsDialog: boolean = false;
  clients: any = [];
  clientsWorks: any = [];
  client: Clients;
  comuni: any = [];
  displayEvent: any;
  currentUser: any;
  items: any;
  appointmentsCount: Appointments;
  billingsCount: Billings;
  billingsCountTotal: Billings;
  projects: any = [];
  project: Projects;
  billingsCountTotalNotPaid: Billings;
  category_id: string;
  canvas: any;
  ctx: any;
  yAxes: [];
  xAxes: [];
  chartsCount: any;
  chartsCountNone: any;
  chartsCountData: any = [];
  chartsCountDataTotal: string;
  data1 = [];
  data2 = [];
  count = 0;
  countAnimations = 0;
  countNews = 0;
  countClients = 0;
  countClientsWorks = 0;

  pageSize = 3;
  page = 1;
  myDate = formatDate(new Date(), 'dd/MM/yyyy', 'en');
  searchDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  cols: any[];
  colsData: any[];
  colsVariations: any[];
  colsDataVariations: any[];
  exportColumns: any[];
  _selectedColumns: any[];

  trackByFn(index, item) {
    return item.id;
  }

  myMonth = formatDate(new Date(), 'dd/MM/yyyy', 'en');
  nameFilter: string;
  descriptionFilter: string;
  dateFromFilter: string;
  dateToFilter: string;
  currentDate: moment.Moment = moment();
  currentTime: string = moment().format(' MMMM YYYY');



  constructor(
    private clientsService: ClientsService,
    private clientsWorksService: ClientsWorksService,
    private spinner: NgxSpinnerService,
    private fb: UntypedFormBuilder,
    private animationsService: AnimationsService,
    private chartsService: ChartsService,
    private router: Router,
    private productsService: ProductsService,
    private newsService: NewsService,
    private messageService: MessageService,
    private supportsService: SupportsService,
    private route: ActivatedRoute
  ) {
    this.typeList = TYPE_LIST;
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '[]');
    this.items = DASHBOARD;



  }

  ngOnInit() {

    const userId = this.currentUser.user_id;
    this.spinner.show();
    this.getAnimations();
    this.getNews();
    this.getClients();
    this.getClientsWorks();
    
    this.cols = [
      { field: "page_title", header: "Titolo" },
    ];

    this._selectedColumns = this.cols;
    this.exportColumns = this.cols.map(col => ({
      title: col.header,
      dataKey: col.field
    }));

      this.spinner.hide();


    
  }

  ngAfterViewInit() {
    //this.getChartsCount();

    //this.chartCanvas();

  }

  getRequestParams(page, searchTitle, categoryTitle, pageSize): any {
    // tslint:disable-next-line:prefer-const
    const params = {};
    let adder = '?';
    if (page) {
      params[`page`] = page - 1;
      adder + 'page=' + (page - 1);
      adder = '&';
    }
    if (searchTitle) {
      params[`name`] = searchTitle;
      adder + 'date_from=' + searchTitle;
      adder = '&';
    }
    if (categoryTitle) {
      params[`description`] = categoryTitle;
      adder + 'date_to=' + categoryTitle;
      adder = '&';
    }
    if (pageSize) {
      params[`size`] = pageSize;
      adder + 'size=' + pageSize;
    }
    
    
    return params;
    
  }


  getAnimations() {
    const params = this.getRequestParams(
      this.nameFilter = '',
      this.descriptionFilter = '',
      this.page = 1,
      this.pageSize = 5
    );

    this.animationsService.getAllListNew(params).subscribe((pData) => {
      this.animations = pData;
      this.countAnimations = this.animationsService.size;

    });

  }


  getNews() {
    const params = this.getRequestParams(
      this.nameFilter = '',
      this.descriptionFilter = '',
      this.page = 1,
      this.pageSize = 5
    );

    this.newsService.getAllListNew(params).subscribe((pData) => {
      this.news = pData;
      this.countNews = this.newsService.size;
    });

  }

  getClients() {
    const params = this.getRequestParams(
      this.nameFilter = '',
      this.descriptionFilter = '',
      this.page = 1,
      this.pageSize = 3
    );

    this.clientsService.getAllListNew(params).subscribe((pData) => {
      this.clients = pData;
      this.countClients = this.clientsService.size;
    });

  }

  getClientsWorks() {
    const params = this.getRequestParams(
      this.nameFilter = '',
      this.descriptionFilter = '',
      this.page = 1,
      this.pageSize = 3
    );

    this.clientsWorksService.getAllListNew(params).subscribe((pData) => {
      this.clientsWorks = pData;
      this.countClientsWorks = this.clientsWorksService.size;
    });

  }





}
