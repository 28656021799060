import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { ChipsModule } from "primeng/chips";
import { AdminRoutingModule } from './admin-routing.module';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminComponent } from './admin/admin.component';
import { NgxSpinnerService } from "ngx-spinner";
import {InputNumberModule} from 'primeng/inputnumber';
import {TreeModule} from 'primeng/tree';
import {TreeNode} from 'primeng/api';
import { ManageClientsComponent } from './clients/manage-clients/manage-clients.component';
import { ClientsFormComponent } from './clients/clients-form/clients-form.component';
import {TabViewModule} from 'primeng/tabview';
import { DateValidPipe } from "./pipe/date-valid.pipe";
import { JoinPipe } from "./pipe/join.pipe";
import { LimitPipe } from "./pipe/limit.pipe";
import { KeysPipe } from "./pipe/key.pipe";
import {InputTextareaModule} from 'primeng/inputtextarea';
import { TableModule } from 'primeng/table';
import { EditorModule } from 'primeng/editor';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import {SelectButtonModule} from 'primeng/selectbutton';
import { ImageModule } from 'primeng/image';

import { RadioButtonModule } from 'primeng/radiobutton';
import { FileUploadModule } from 'primeng/fileupload';
import { HttpClientModule, HttpClient} from '@angular/common/http';
import { CalendarModule } from 'primeng/calendar';
import { MomentPipe } from './pipe/moment.pipe';
import { InputMaskModule } from 'primeng/inputmask';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CardModule } from 'primeng/card';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {ToastModule} from 'primeng/toast';
import {TooltipModule} from 'primeng/tooltip';
import {MultiSelectModule} from 'primeng/multiselect';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import * as $ from 'jquery';
import {TimelineModule} from 'primeng/timeline';
import { TagModule } from 'primeng/tag';
import { NgxSpinnerModule } from "ngx-spinner";
import {PanelModule} from 'primeng/panel';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {KnobModule} from 'primeng/knob';
import { SettingsComponent } from './settings/settings.component';
import { SumPipeModule } from './pipe/sum.pipe';
import { ShortNumberPipe } from './pipe/short-number.pipe';
import {SplitButtonModule} from 'primeng/splitbutton';
import {PanelMenuModule} from 'primeng/panelmenu';
import { SplitCommaStringPipe } from './pipe/split.pipe';
import { TotalPipe } from './pipe/total.pipe';
import {ProgressBarModule} from 'primeng/progressbar';
import { NgxPaginationModule } from 'ngx-pagination';
import {TreeDragDropService} from 'primeng/api';
import {TabMenuModule} from 'primeng/tabmenu';
import { TopNavComponent } from '../top-nav/top-nav.component';
import { ManageAnimationsComponent } from './animations/manage-animations/manage-animations.component';
import { AnimationsFormComponent } from './animations/animations-form/animations-form.component';
import { NewsFormComponent } from './news/news-form/news-form.component';
import { ManageNewsComponent } from './news/manage-news/manage-news.component';
import { ManagePagesComponent } from './pages/manage-pages/manage-pages.component';
import { PagesFormComponent } from './pages/pages-form/pages-form.component';
import { FeaturesPersonsFormComponent } from './settings/features_persons/features-persons-form/features-persons-form.component';
import { ManageFeaturesPersonsComponent } from './settings/features_persons/manage-features-persons/manage-features-persons.component';
import { FeaturesTypologyFormComponent } from './settings/features_typologoy/features-typology-form/features-typology-form.component';
import { ManageFeaturesTypologyComponent } from './settings/features_typologoy/manage-features-typology/manage-features-typology.component';
import { ManageFeaturesAreaComponent } from './settings/features_area/manage-features-area/manage-features-area.component';
import { FeaturesAreaFormComponent } from './settings/features_area/features-area-form/features-area-form.component';
import { ManageFeaturesDestinationsComponent } from './settings/features_destinations/manage-features-destinations/manage-features-destinations.component';
import { FeaturesDestinationsFormComponent } from './settings/features_destinations/features-destinations-form/features-destinations-form.component';
import { FeaturesTechnicalDataFormComponent } from './settings/features_technical_data/features-technical-data-form/features-technical-data-form.component';
import { ManageFeaturesTechncialDataComponent } from './settings/features_technical_data/manage-features-techncial-data/manage-features-technical-data.component';
import { ClientsWorksFormComponent } from './client-works/clients-works-form/clients-works-form.component';
import { ManageClientsWorksComponent } from './client-works/manage-clients-works/manage-clients-works.component';
import { FeaturesSectorsFormComponent } from './settings/features_sector/features-sectors-form/features-sectors-form.component';
import { ManageFeaturesSectorsComponent } from './settings/features_sector/manage-features-sectors/manage-features-sectors.component';
import { ManageFeaturesProvincesComponent } from './settings/features_provinces/manage-features-sectors/manage-features-provinces.component';
import { FeaturesProvincesFormComponent } from './settings/features_provinces/features-provinces-form/features-provinces-form.component';
import { IImageLoaderOptions, NgxProgressiveImageLoaderModule } from 'ngx-progressive-image-loader';

import { GoogleMapsModule } from '@angular/google-maps';
import { UploadComponent } from './components/upload/upload.component';
import { UploadFilesComponent } from './components/upload-files/upload-files.component';
import { ManageUsersComponent } from './users/manage-users/manage-users.component';
import { UsersFormComponent } from './users/users-form/users-form.component';
import { SlugTransformDirective } from './pipe/slug-transform.directive';
import { InitialsAvatarComponent } from './components/initials-avatar/initials-avatar.component';
import { FileSizePipe } from './pipe/filesize.pipe';
import { AnimationsCardComponent } from './components/animations-card/animations-card.component';
import { NgOptimizedImage } from '@angular/common'


@NgModule({
  declarations: [
    ManageAnimationsComponent,
    ManageFeaturesTypologyComponent,
    ManageFeaturesProvincesComponent,
    FeaturesProvincesFormComponent,
    ManageUsersComponent,
    UsersFormComponent,
    FeaturesTypologyFormComponent,
    ManageFeaturesSectorsComponent,
    FeaturesSectorsFormComponent,
    ClientsWorksFormComponent,
    ManageClientsWorksComponent,
    ManageFeaturesDestinationsComponent,
    FeaturesDestinationsFormComponent,
    FeaturesTechnicalDataFormComponent,
    ManageFeaturesTechncialDataComponent,
    ManageFeaturesAreaComponent,
    FeaturesAreaFormComponent,
    AnimationsFormComponent,
    AdminDashboardComponent,
    ManagePagesComponent,
    PagesFormComponent,
    AdminComponent,
    ManageClientsComponent,
    FeaturesPersonsFormComponent,
    ClientsFormComponent,
    ManageFeaturesPersonsComponent,
    NewsFormComponent,
    ManageNewsComponent,
    MomentPipe,
    TopNavComponent,
    DateValidPipe,
    JoinPipe,
    KeysPipe,
    ShortNumberPipe,
    UploadFilesComponent,
    LimitPipe,
    TotalPipe,
    FileSizePipe,
    UploadComponent,
    InitialsAvatarComponent,
    SplitCommaStringPipe,
    SettingsComponent,
    SlugTransformDirective,
    AnimationsCardComponent
    ],
    exports: [
      LimitPipe,
      KeysPipe,
      FileSizePipe,
      ShortNumberPipe,
      TotalPipe,
      SlugTransformDirective,
      DragDropModule,
    ],
  imports: [
    CommonModule,
    TableModule,
    MessagesModule,
    GoogleMapsModule,
    SumPipeModule,
    TreeModule,
    TooltipModule,
    NgOptimizedImage,
    InputNumberModule,
    SelectButtonModule,
    ProgressBarModule,
    NgxSpinnerModule,
    KnobModule,
    TabMenuModule,
    InputTextareaModule,
    NgxPaginationModule,
    ImageModule,
    DragDropModule,
    SplitButtonModule,
    ToastModule,
    TabViewModule,
    OverlayPanelModule,
    MessageModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    PanelModule,
    CheckboxModule,
    EditorModule,
    InputMaskModule,
    CardModule,
    PanelMenuModule,
    ChipsModule,
    TagModule,
    ConfirmDialogModule,
    TimelineModule,
    InputTextModule,
    AdminRoutingModule,
    DialogModule,
    RadioButtonModule,
    CalendarModule,
    DropdownModule,
    HttpClientModule,
    FileUploadModule,
    MultiSelectModule,
    ButtonModule,
    InputSwitchModule,
    AutoCompleteModule,
    SidebarModule,
    NgOptimizedImage,
    NgxProgressiveImageLoaderModule.forRoot(<IImageLoaderOptions>{
      // rootMargin must be specified in pixels or percent
      rootMargin: '30px',
      threshold: 0.1,
      // css filter
      filter: 'blur(3px) drop-shadow(0 0 0.75rem crimson)',
      // image width / height ratio for image holder
      // loading image in placeholder. Can be URL or base64
    }),
   
  ],
  providers: [ConfirmationService, TreeDragDropService, MessageService, SumPipeModule,  NgxSpinnerService]
})
export class AdminModule { }


