<app-topnav></app-topnav>

<form [formGroup]="blogForm" (ngSubmit)="onSubmit()">
    <div class="top-main-wrapper">
        <div class="dash-header">
            <div class="dash-title">

                <div class="p-field p-grid">
                    <div class="p-col-6 p-md-6">
                        <h3>{{pageTitle}}</h3>
                    </div>
                    <div class="p-col-6 p-md-6">

                        <button pButton pRipple icon="pi pi-save" label="salva"
                            class="p-button p-button-success p-mr-2 pull-right" [disabled]="!blogForm.valid"></button>

                        <button type="button" pButton pRipple icon="pi pi-chevron-left"
                            label=" indietro" class="p-button p-button-back pull-right  p-mr-2"
                            [routerLink]="['/admin/clients/']"></button>
                    </div>
                </div>

            </div>
        </div>
    </div>

    
    <div class="main-inner">

        <div class="p-fluid">
            <div class="p-field p-grid">
                <div class="p-col-12 p-md-8">

                    <p-tabView class="responsive-tab-view">
                        <p-tabPanel header="Informazioni">

                            <p-card>

                                <div class="blog-form">



                                    <div class="p-fluid">

                                        <div class="p-field p-grid">
                                            <label class="p-col-12 p-mb-2 p-md-12 p-mb-md-0">Nome <span
                                                    class="required">*</span></label>
                                            <div class="p-col-12 p-md-12">
                                                <input type="text" pInputText placeholder="Ente"
                                                    formControlName="company" />
                                            </div>

                                        </div>
                                    </div>





                                    <div class="p-fluid">

                                        <div class="p-field p-grid">
                                            <label class="p-col-12 p-mb-2 p-md-12 p-mb-md-0">Indirizzo <span
                                                    class="required">*</span></label>
                                            <div class="p-col-12 p-md-12">
                                                <input type="text" pInputText formControlName="address" />

                                            </div>
                                        </div>
                                    </div>


                                    <div class="p-field p-grid">
                                        <label class="p-col-12 p-mb-2 p-md-12 p-mb-md-0">Provincia</label>
                                        <div class="p-col-12 p-md-12">
                                            <p-dropdown [options]="provinceList"
                                                [required]="true" [showClear]="true" placeholder="Scegli"
                                                [style]="{ width: '100%' }" formControlName="province"
                                                optionLabel="name" optionValue="id">
                                                <ng-template let-option pTemplate="item">
                                                    {{option.name}}
                                                </ng-template>
                                            </p-dropdown>
                                        </div>
                                    </div>

                                    <div class="p-field p-grid">
                                        <label class="p-col-12 p-mb-2 p-md-12 p-mb-md-0">Settore</label>
                                        <div class="p-col-12 p-md-12">
                                            <p-dropdown [options]="sectorList"
                                                [required]="true" [showClear]="true" placeholder="Scegli"
                                                [style]="{ width: '100%' }" formControlName="sector" optionLabel="name"
                                                optionValue="id">
                                                <ng-template let-option pTemplate="item">
                                                    {{option.name}}
                                                </ng-template>
                                            </p-dropdown>
                                        </div>
                                    </div>

                                    <div class="p-fluid">

                                        <div class="p-field p-grid">
                                            <label class="p-col-12 p-mb-2 p-md-12 p-mb-md-0">Sito web <span
                                                    class="required">*</span></label>
                                            <div class="p-col-12 p-md-12">
                                                <input type="text" pInputText formControlName="website" />

                                            </div>
                                        </div>
                                    </div>



                                    <div class="p-fluid">

                                        <div class="p-field p-grid">
                                            <label class="p-col-12 p-mb-2 p-md-12 p-mb-md-0">Cellulare <span
                                                    class="required">*</span></label>
                                            <div class="p-col-12 p-md-12">
                                                <input type="text" pInputText formControlName="phone" />

                                            </div>
                                        </div>
                                    </div>

                                    <div class="p-fluid">

                                        <div class="p-field p-grid">
                                            <label class="p-col-12 p-mb-2 p-md-12 p-mb-md-0">Posizione <span
                                                    class="required">*</span></label>
                                            <div class="p-col-12 p-md-12">
                                                <input type="text" pInputText formControlName="position" />

                                            </div>
                                        </div>
                                    </div>

                                    <div class="p-fluid">
                                        <div class="p-field p-grid">
                                            <label class="p-col-12 p-mb-2 p-md-12 p-mb-md-0">Attivo <span
                                                    class="required">*</span></label>
                                            <div class="p-col-12 p-md-12">

                                                <p-selectButton [options]="stateOptions" formControlName="is_active"
                                                    optionLabel="label" optionValue="value"></p-selectButton>
                                            </div>
                                        </div>
                                    </div>



                                    <input type="hidden" formControlName="id">


                                </div>
                            </p-card>
                        </p-tabPanel>


                    </p-tabView>
                  
                </div>

                <div class="p-col-12 p-md-4">
                    <p-tabView class="responsive-tab-view">

                        <p-tabPanel header="Elenco lavori"> 

                            <google-map [options]="mapOptions" [center]="center" height="300px" width="100%">

                                <map-marker [options]="markerOptions" #markerElem="mapMarker" [position]="marker">
                                </map-marker>
    
                            </google-map>

                        </p-tabPanel>

                    </p-tabView>
                </div>
            </div>
        </div>
    </div>
</form>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" #cd>

    <p-footer>
        <button type="button" pButton icon="fa fa-close" class="p-button p-button-danger p-mr-2" label="No"
            (click)="cd.reject()"></button>
        <button type="button" pButton icon="fa fa-check" class="p-button p-button-success p-mr-2" label="Si"
            (click)="cd.accept()"></button>
    </p-footer>

</p-confirmDialog>

<p-toast key="myKey1"></p-toast>