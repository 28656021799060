import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';

import { AdminComponent } from './admin/admin.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';

import { ManageClientsComponent } from './clients/manage-clients/manage-clients.component';
import { ClientsFormComponent } from './clients/clients-form/clients-form.component';
import { SettingsComponent } from './settings/settings.component';
import { ManageAnimationsComponent } from './animations/manage-animations/manage-animations.component';
import { AnimationsFormComponent } from './animations/animations-form/animations-form.component';
import { ManageNewsComponent } from './news/manage-news/manage-news.component';
import { NewsFormComponent } from './news/news-form/news-form.component';
import { ManagePagesComponent } from './pages/manage-pages/manage-pages.component';
import { PagesFormComponent } from './pages/pages-form/pages-form.component';
import { ManageFeaturesPersonsComponent } from './settings/features_persons/manage-features-persons/manage-features-persons.component';
import { FeaturesPersonsFormComponent } from './settings/features_persons/features-persons-form/features-persons-form.component';
import { ManageFeaturesTypologyComponent } from './settings/features_typologoy/manage-features-typology/manage-features-typology.component';
import { FeaturesTypologyFormComponent } from './settings/features_typologoy/features-typology-form/features-typology-form.component';
import { ManageFeaturesAreaComponent } from './settings/features_area/manage-features-area/manage-features-area.component';
import { FeaturesAreaFormComponent } from './settings/features_area/features-area-form/features-area-form.component';
import { ManageFeaturesDestinationsComponent } from './settings/features_destinations/manage-features-destinations/manage-features-destinations.component';
import { FeaturesDestinationsFormComponent } from './settings/features_destinations/features-destinations-form/features-destinations-form.component';
import { FeaturesTechnicalDataFormComponent } from './settings/features_technical_data/features-technical-data-form/features-technical-data-form.component';
import { ManageFeaturesTechncialDataComponent } from './settings/features_technical_data/manage-features-techncial-data/manage-features-technical-data.component';
import { ManageClientsWorksComponent } from './client-works/manage-clients-works/manage-clients-works.component';
import { ClientsWorksFormComponent } from './client-works/clients-works-form/clients-works-form.component';
import { ManageFeaturesSectorsComponent } from './settings/features_sector/manage-features-sectors/manage-features-sectors.component';
import { FeaturesSectorsFormComponent } from './settings/features_sector/features-sectors-form/features-sectors-form.component';
import { ManageFeaturesProvincesComponent } from './settings/features_provinces/manage-features-sectors/manage-features-provinces.component';
import { FeaturesProvincesFormComponent } from './settings/features_provinces/features-provinces-form/features-provinces-form.component';
import { ManageUsersComponent } from './users/manage-users/manage-users.component';
import { UsersFormComponent } from './users/users-form/users-form.component';

const routes: Routes = [
  {
    path: "admin",
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        children: [



          { path: "clients", component: ManageClientsComponent },
          { path: "clients/create", component: ClientsFormComponent },
          { path: "clients/edit/:id", component: ClientsFormComponent },


          { path: "clients-works", component: ManageClientsWorksComponent },
          { path: "clients-works/create", component: ClientsWorksFormComponent },
          { path: "clients-works/edit/:id", component: ClientsWorksFormComponent },

          { path: "settings", component: SettingsComponent },

          { path: "settings/features-persons", component: ManageFeaturesPersonsComponent },
          { path: "settings/features-persons/edit/:id", component: FeaturesPersonsFormComponent },
          { path: "settings/features-persons/create", component: FeaturesPersonsFormComponent },

          { path: "settings/features-sectors", component: ManageFeaturesSectorsComponent },
          { path: "settings/features-sectors/edit/:id", component: FeaturesSectorsFormComponent },
          { path: "settings/features-sectors/create", component: FeaturesSectorsFormComponent },

          { path: "settings/features-provinces", component: ManageFeaturesProvincesComponent },
          { path: "settings/features-provinces/edit/:id", component: FeaturesProvincesFormComponent },
          { path: "settings/features-provinces/create", component: FeaturesProvincesFormComponent },

          { path: "settings/features-area", component: ManageFeaturesAreaComponent },
          { path: "settings/features-area/edit/:id", component: FeaturesAreaFormComponent },
          { path: "settings/features-area/create", component: FeaturesAreaFormComponent },

          { path: "settings/features-destinations", component: ManageFeaturesDestinationsComponent },
          { path: "settings/features-destinations/edit/:id", component: FeaturesDestinationsFormComponent },
          { path: "settings/features-destinations/create", component: FeaturesDestinationsFormComponent },

          { path: "settings/features-technical-data", component: ManageFeaturesTechncialDataComponent },
          { path: "settings/features-technical-data/edit/:id", component: FeaturesTechnicalDataFormComponent },
          { path: "settings/features-technical-data/create", component: FeaturesTechnicalDataFormComponent },


          { path: "settings/features-typology", component: ManageFeaturesTypologyComponent },
          { path: "settings/features-typology/edit/:id", component: FeaturesTypologyFormComponent },
          { path: "settings/features-typology/create", component: FeaturesTypologyFormComponent },


          { path: "animations", component: ManageAnimationsComponent },
          { path: "animations/create", component: AnimationsFormComponent },
          { path: "animations/edit/:id",component: AnimationsFormComponent},

          { path: "news", component: ManageNewsComponent },
          { path: "news/create", component: NewsFormComponent },
          { path: "news/edit/:id",component: NewsFormComponent},

          { path: "pages", component: ManagePagesComponent },
          { path: "pages/create", component: PagesFormComponent },
          { path: "pages/edit/:id",component: PagesFormComponent},


          { path: "users", component: ManageUsersComponent },
          { path: "users/create", component: UsersFormComponent },
          { path: "users/edit/:id",component: UsersFormComponent},



          { path: "", component: AdminDashboardComponent },

        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
