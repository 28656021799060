<app-topnav></app-topnav>

<form [formGroup]="blogForm" (ngSubmit)="onSubmit()">
    <div class="top-main-wrapper">
        <div class="dash-header">
            <div class="dash-title">

                <div class="p-field p-grid">
                    <div class="p-col-6 p-md-6">
                        <h3>{{pageTitle}}</h3>
                    </div>
                    <div class="p-col-6 p-md-6">

                        <button pButton pRipple icon="pi pi-save" label="salva"
                            class="p-button p-button-success p-mr-2 pull-right" [disabled]="!blogForm.valid"></button>

                        <button type="button" pButton pRipple icon="pi pi-chevron-left"
                            label=" indietro" class="p-button p-button-back pull-right  p-mr-2"
                            [routerLink]="['/admin/clients-works/']"></button>
                    </div>
                </div>

            </div>
        </div>
    </div>





    <div class="main-inner">

        <div class="p-fluid">
            <div class="p-field p-grid">
                <div class="p-col-12 p-md-12">
                    <p-tabView class="responsive-tab-view">
                        <p-tabPanel header="Informazioni">


                            <p-card>
                                <div class="p-grid">

                                    <div class="p-col-12 p-md-7">
                                        <div class="left-section-card">
                                            <div class="p-fluid">

                                                <div class="p-field p-grid">
                                                    <label class="p-col-12 p-mb-2 p-md-12 ">Nome <span
                                                            class="required">*</span></label>
                                                    <div class="p-col-12 p-md-12">
                                                        <input type="text" pInputText placeholder="Nome"
                                                            formControlName="page_title" />
                                                    </div>

                                                </div>
                                            </div>





                                            <div class="p-fluid">

                                                <div class="p-field p-grid">
                                                    <label class="p-col-12 p-mb-2 p-md-12 ">SEO URL <span
                                                            class="required">*</span></label>
                                                    <div class="p-col-12 p-md-12">
                                                        <input type="text" pInputText formControlName="page_url" />

                                                    </div>
                                                </div>
                                            </div>


                                            
                                            <div class="p-fluid">

                                                <div class="p-field p-grid">
                                                    <label class="p-col-12 p-mb-2 p-md-12 ">Descrizione
                                                        completa <span class="required">*</span></label>
                                                    <div class="p-col-12 p-md-12">
                                                        <div class="p-grid">
                                                            <div class="p-col-12 p-md-12">

                                                                <p-editor formControlName="description_1"
                                                                    [style]="{ height: '140px' }">
                                                                    <ng-template pTemplate="header">
                                                                        <span class="ql-formats">
                                                                            <button type="button" class="ql-bold"
                                                                                aria-label="Bold"></button>
                                                                            <button type="button" class="ql-italic"
                                                                                aria-label="Italic"></button>
                                                                            <button type="button" class="ql-underline"
                                                                                aria-label="Underline"></button>
                                                                        </span>
                                                                    </ng-template>
                                                                </p-editor>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                            <div class="p-fluid">

                                                <div class="p-field p-grid">
                                                    <label class="p-col-12 p-mb-2 p-md-12 ">Descrizione
                                                        completa <span class="required">*</span></label>
                                                    <div class="p-col-12 p-md-12">
                                                        <div class="p-grid">
                                                            <div class="p-col-12 p-md-12">

                                                                <p-editor formControlName="description_2"
                                                                    [style]="{ height: '140px' }">
                                                                    <ng-template pTemplate="header">
                                                                        <span class="ql-formats">
                                                                            <button type="button" class="ql-bold"
                                                                                aria-label="Bold"></button>
                                                                            <button type="button" class="ql-italic"
                                                                                aria-label="Italic"></button>
                                                                            <button type="button" class="ql-underline"
                                                                                aria-label="Underline"></button>
                                                                        </span>
                                                                    </ng-template>
                                                                </p-editor>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>





                                            

                                            <div class="form-group form-row">
                                                <label class="col-md-3"></label>
                                                <div class="col-md-9">
                                                    <input type="hidden" formControlName="id">

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="p-col-12 p-md-5">
                                        <div class="p-grid">

                                        <label class="p-col-12 p-mb-2 p-md-12">Opzioni</label>
                                        </div>
                                        <div class="well">
                                        <div class="p-fluid">
                                            <div class="p-field p-grid">
                                                <label class="p-col-12 p-mb-2 p-md-12 ">Attivo <span
                                                        class="required">*</span></label>
                                                <div class="p-col-12 p-md-12">

                                                    <p-selectButton [options]="stateOptions"
                                                        formControlName="is_active" optionLabel="label"
                                                        optionValue="value"></p-selectButton>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="p-field p-grid">
                                            <label class="p-col-12 p-mb-2 p-md-12 ">Cliente</label>
                                            <div class="p-col-12 p-md-12">
                                                <p-dropdown [options]="clients"
                                                    [required]="true" [showClear]="true" placeholder="Scegli"
                                                    [style]="{ width: '100%' }" formControlName="selected_company"
                                                    optionLabel="name" optionValue="id">
                                                    <ng-template let-option pTemplate="item">
                                                        <div class="p-grid">

                                                            <div class="p-col-2 p-md-1">
                                                                {{option.id}}
                                                            </div>
                                                            <div class="p-col-10 p-md-11">
                                                                {{option.name}}
                                                            </div>
                                                        </div>

                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                        </div>


                                        <div class="p-field p-grid">
                                            <label class="p-col-12 p-mb-2 p-md-12 ">Anno</label>
                                            <div class="p-col-12 p-md-12">
                                                <p-dropdown [options]="yearsList"
                                                    [required]="true" [showClear]="true" placeholder="Scegli"
                                                    [style]="{ width: '100%' }" formControlName="year"
                                                    optionLabel="value" optionValue="value">
                                                    <ng-template let-option pTemplate="item">
                                                        {{option.value}}


                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                        </div>

                                        <div class="p-field p-grid">
                                            <label class="p-col-12 p-mb-2 p-md-12 ">Settore</label>
                                            <div class="p-col-12 p-md-12">
                                                <p-multiSelect [options]="sectorList"
                                                    display="chip" [required]="true"
                                                    [showClear]="true" placeholder="Scegli"
                                                    [style]="{ width: '100%' }" formControlName="selected_sector"
                                                    optionLabel="name" optionValue="id">
                                                    <ng-template let-option pTemplate="item">
                                                        {{option.name}}
                                                    </ng-template>
                                                </p-multiSelect>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </p-card>
                        </p-tabPanel>


                    </p-tabView>

                </div>


            </div>
        </div>
    </div>
</form>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" #cd>

    <p-footer>
        <button type="button" pButton icon="fa fa-close" class="p-button p-button-danger p-mr-2" label="No"
            (click)="cd.reject()"></button>
        <button type="button" pButton icon="fa fa-check" class="p-button p-button-success p-mr-2" label="Si"
            (click)="cd.accept()"></button>
    </p-footer>

</p-confirmDialog>

<p-toast key="myKey1"></p-toast>