import { Component, ElementRef, OnInit } from "@angular/core";
import { ClientsService } from "../../../services/clients.service";
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
  UntypedFormArray,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ViewChild } from "@angular/core";
import { Clients } from "../../../models/clients";
import { Category } from "../../../models/category";
import { UntypedFormControl } from "@angular/forms";
import { CategoryService } from "../../../services/categories.service";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import * as moment from "moment";
import {
  FISCAL_CODE_VALIDATOR_REGEX,
  SEX_LIST,
  STATE_LIST,
  BUSINESS_STATE_LIST,
  YEARSLIST,
} from "../../constants/constants";
import { ComuniService } from "src/app/services/comuni.service";
import { Comuni } from "src/app/models/comuni";
import { Location } from "@angular/common";
import { AppointmentsService } from "src/app/services/appointments.service";
import { BillingsService } from "src/app/services/billings.service";
import { Billings } from "src/app/models/billings";
import { PersonalDataService } from "src/app/services/personal_data.service";
import { Personal_data } from "src/app/models/personal_data";
import { NgxSpinnerService } from "ngx-spinner";
import { ClientsWorksService } from "src/app/services/clients_works.service";
import { GoogleMap, MapInfoWindow, MapMarker } from "@angular/google-maps";
import { SectorService } from "src/app/services/sector.service";
import { Sector } from "src/app/models/sector";

@Component({
  selector: "app-clients-works-form",
  templateUrl: "./clients-works-form.component.html",
})
export class ClientsWorksFormComponent implements OnInit {
  @ViewChild("myInput", { static: false }) myInputVariable: ElementRef;

  pageTitle: string;
  error: string;
  uploadError: string;
  imagePath: any;
  clients: Clients[] = [];
  client: Clients;

  clientsList: any = [];
  clientList: Clients;

  categories: any = [];
  category: Category;
  checked: boolean = true;
  selectedValue: string;
  comuni: Comuni;

  blogForm: UntypedFormGroup;
  typeList: any[];
  yearsList = YEARSLIST;

  cities: Clients[];
  format1: string = "";
  format2: string = "";
  selectedCity: Clients;
  selectedCategories: Category;
  selectedDate: Date;
  date: Date;
  appointments: any = [];
  is_featured = "0";
  deleteButton: boolean;
  billings: any = [];
  billing: Billings;
  currentUser: any;
  addForm: UntypedFormGroup;
  rows: UntypedFormArray;
  itemForm: UntypedFormGroup;
  skillsForm: UntypedFormGroup;
  skillsValues: any = [];
  stateOptions: any[];
  cols: any[];
  exportColumns: any[];
  _selectedColumns: any[];
  businessStateOptions: any[];
  personal_datas: any = [];
  personal_data: Personal_data;
  personName: string;
  mapOptions: any = [];
  infoContent = "";
  markers: any = [];
  zoom: string;
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [5, 10, 15];
  public base_path: string;
  basePath: string;
  pageOfItems: Array<any>;
  searchWrapper: boolean = false;
  nameFilter: string;
  descriptionFilter: string;
  sectorList: Sector[] = [];

  trackByFn(index, item) {
    return item.id;
  }

  center: google.maps.LatLngLiteral = { lat: 44.99266, lng: 10.74941 };

  constructor(
    private fb: UntypedFormBuilder,
    private clientsWorksService: ClientsWorksService,
    private messageService: MessageService,
    private clientsService: ClientsService,
    private comuniService: ComuniService,
    private _location: Location,
    private spinner: NgxSpinnerService,
    private confirmationService: ConfirmationService,
    private sectorsService: SectorService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.mapOptions = {
      streetViewControl: false,
      mapTypeControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scaleControl: true,
      zoom: 9,
    };

    this.currentUser = JSON.parse(localStorage.getItem("currentUser") || "[]");
    this.stateOptions = STATE_LIST;
    this.businessStateOptions = BUSINESS_STATE_LIST;
  }

  ngOnInit() {
    this.spinner.show();
    this.getSectorList();

    const id = this.route.snapshot.paramMap.get("id");

   const params = [
      this.nameFilter,
      this.descriptionFilter,
      this.page = 1,
      this.pageSize = 100
    ];
    
    this.clientsService.getAllListNew(params).subscribe((pData) => {
      this.clients = pData;
      this.count = this.clientsService.size;
    });

    if (id) {
      this.pageTitle = "Modifica Lavoro Cliente";
      this.deleteButton = true;

      this.clientsWorksService.getId(+id).subscribe((res) => {
        this.blogForm.patchValue({
          page_title: res.page_title,
          description_1: res.description_1,
          description_2: res.description_2,
          year: res.year,
          selected_company: res.selected_company,
          selected_sector: res.selected_sector.split(','),
          is_active: res.is_active,
          page_url: res.page_url,
          id: res.id,
        });
      });
    } else {
      this.deleteButton = false;
      this.pageTitle = "Aggiungi Cliente";
    }

    this.blogForm = this.fb.group({
      id: [""],
      page_title: ["", Validators.required],
      description_1: [""],
      description_2: [""],
      page_url: [""],
      year: [""],
      selected_company: [""],
      selected_sector:  [""],
      is_active: ["0"],
    });

    this.spinner.hide();

    
  }

  
  getSectorList() {
    this.sectorsService.getAllList().subscribe(data => {
      this.sectorList = data;
    });
  }

  removeQuantity(i: number) {
    this.skills.removeAt(i);
  }

  getCategoryItem(category_id: string, id: string) {
    return this.categories.find((item) => item.id === category_id);
  }

  hasNoSelectedAppointments() {
    return (
      this.appointments.filter((appointment) => appointment.title).length === 0
    );
  }

  hasNoSelectedBillings() {
    return this.billings.filter((billing) => billing.title).length === 0;
  }

  onDelete(id: number, title: string) {
    this.confirmationService.confirm({
      message: "Sei sicuro di volerlo cancellare",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.clientsWorksService.delete(+id).subscribe(
          (res) => {
            console.log(res);
            this.messageService.add({
              key: "cancel",
              severity: "success",
              summary: "Attenzione",
              detail: "Cancellazione avvenuto con successo",
            });
            this._location.back();
          },
          (error) => {
            this.error = error;
            this.messageService.add({
              key: "cancel",
              severity: "warn",
              summary: "Attenzione",
              detail: "Errore backend",
            });
          }
        );
      },
    });
  }

  initSkill() {
    var formArray = this.fb.array([]);
    const id = this.route.snapshot.paramMap.get("id");

    this.clientsWorksService.skills(+id).subscribe((res) => {
      this.skillsValues = res;

      this.skillsValues.forEach((e) => {
        formArray.push(
          this.fb.group({
            qty: [e.qty],
            price: [e.price],
          })
        );
      });
    });

    /*formArray.push(this.fb.group({
      qty: [''],
      price: ['']
    })) */

    return formArray;
  }

  private createSkillFormGroup(skill: any): UntypedFormGroup {
    return new UntypedFormGroup({
      qty: new UntypedFormControl(skill.qty),
      price: new UntypedFormControl(skill.price),
    });
  }

  public addSkill(skill: any) {
    this.skills.push(this.createSkillFormGroup(skill));
  }

  get skills() {
    return this.blogForm.get("skills") as UntypedFormArray;
  }

  newQuantity(): UntypedFormGroup {
    return this.fb.group({
      qty: "",
      price: "",
    });
  }

  addQuantity() {
    this.skills.push(this.newQuantity());
  }

  removeImageFile() {
    this.imagePath = "";
    console.log(this.myInputVariable.nativeElement.files);
    this.myInputVariable.nativeElement.value = "";
    console.log(this.myInputVariable.nativeElement.files);
  }

  get title() {
    return this.blogForm.get("title");
  }

  get id() {
    return this.blogForm.get("id").value;
  }

  get description() {
    return this.blogForm.get("description");
  }

  onSubmit() {

    const formData = new FormData();
    formData.append("page_title", this.blogForm.get("page_title").value);
    formData.append("description_1", this.blogForm.get("description_1").value);
    formData.append("description_2", this.blogForm.get("description_2").value);
    formData.append("page_url", this.blogForm.get("page_url").value);
    formData.append("year", this.blogForm.get("year").value);
    formData.append("selected_company", this.blogForm.get("selected_company").value);
    formData.append("selected_sector", this.blogForm.get("selected_sector").value);
    formData.append("is_active", this.blogForm.get("is_active").value);
    const id = this.blogForm.get("id").value;

    if (id) {
      this.clientsWorksService.update(formData, +id).subscribe(
        (res) => {
          if (res.status == "error") {
            this.uploadError = res.message;
          } else {
            this.messageService.add({
              key: "myKey1",
              severity: "success",
              summary: "Attenzione",
              detail: "Salvato con sucesso",
            });
            // this._location.back();
          }
        },
        (error) => (this.error = error)
      );
    } else {
      this.clientsWorksService.create(formData).subscribe(
        (res) => {
          if (res.status === "error") {
            this.uploadError = res.message;
          } else {
            this.messageService.add({
              key: "myKey1",
              severity: "success",
              summary: "Attenzione",
              detail: "Salvato con sucesso",
            });
            this.router.navigate(["/admin/clients-works"]);
          }
        },
        (error) => (this.error = error)
      );
    }
  }
}

export function codFisc(c: UntypedFormControl): { [s: string]: boolean } {
  if (c.value && !c.value.match(FISCAL_CODE_VALIDATOR_REGEX)) {
    return { invalidCF: true };
  }
}
