<p-card class="dashboard-animations-card">
        <div class="p-grid">
            <div class="p-col-12 p-md-3">

             
                <ngx-progressive-image-loader filter="blur(3px)
                drop-shadow(0 0 2.75rem green)">



                <div class="image-home"  [routerLink]="['/admin/animations/edit', element.id]">
                <img [src]="heroimage" width="100" height="100"  class="img-fluid cp object-fit_cover" priority>
                </div>

                </ngx-progressive-image-loader>
              


            </div>
            <div class="p-col-12 p-md-9">

                <div class="content">


                    <div class="p-grid">
                        <div class="p-col-12 p-md-12">

                    <h3 class="p-mt-0">{{ element.page_title }}</h3>
                    <a [routerLink]="['/admin/animations/edit', element.id]"  class="p-button p-button-primary p-mt-2">modifica</a>
                    <a [routerLink]="['/admin/animations/edit', element.id]"  class="p-button p-button-secondary p-mt-2 p-ml-2">visualizza</a>

                        </div>

                       

                    </div>
               
                </div>
            </div>
    </div>
</p-card>