<form [formGroup]="categoryForm" (ngSubmit)="onSubmit()">



    <div class="top-main-wrapper">
        <div class="dash-header">
            <div class="dash-title">

                <div class="p-field p-grid">
                    <div class="p-col-12 p-md-4">
                        <h3>{{pageTitle}}</h3>
                    </div>
                    <div class="p-col-12 p-md-8">
                        <button pButton pRipple label="salva" class="p-button p-button-primary pull-right p-mr-2"
                            type="submit" [disabled]="!categoryForm.valid"></button>


                        <button type="button" pButton pRipple icon="pi pi-chevron-left" label=" indietro"
                            class="p-button p-button-back pull-right  p-mr-2"
                            [routerLink]="['/admin/settings/features-sectors/']"></button>

                    </div>
                </div>

            </div>
        </div>

    </div>

    <div class="main-inner">

        <div class="p-fluid">
            <div class="p-field p-grid">
                <div class="p-col-8 p-md-12">

                    <p-card>
                        <div class="p-grid">
                            <div class="p-col-12 p-md-7">
                                <div class="left-section-card">
                                    <div class="p-fluid">

                                        <div class class="blog-form">

                                            <div class="p-fluid">
                                                <div class="p-field p-grid">
                                                    <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Name <span
                                                            class="required">*</span></label>
                                                    <div class="p-col-12 p-md-10">
                                                        <input type="text" [style]="{ width: '100%' }" pInputText
                                                            formControlName="name" placeholder="Name" required />
                                                        <div *ngIf="category_name.invalid && (category_name.dirty || category_name.touched)"
                                                            class="error">
                                                            <div *ngIf="category_name.errors">
                                                                Title is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <div class="p-fluid">
                                                <div class="p-field p-grid">
                                                    <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Descrizione <span
                                                            class="required">*</span></label>
                                                    <div class="p-col-12 p-md-10">

                                                        <input type="text" [style]="{ width: '100%' }" pInputText
                                                            formControlName="description" placeholder="Descrizione" />

                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>

                                </div>

                            </div>
                            
                            <div class="p-col-12 p-md-5">
                                    <div class="p-field p-grid">

                                        <div class="p-col-12">
                                            <div class="well">
                                                <div class="p-fluid">
                                                    <div class="p-field p-grid">
                                                        <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Attivo <span
                                                                class="required">*</span></label>
                                                        <div class="p-col-12 p-md-10">
                                                            <p-selectButton [options]="stateOptions"
                                                                formControlName="status" optionLabel="label"
                                                                optionValue="value"></p-selectButton>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="form-group form-row">
                                                    <label class="col-md-3"></label>
                                                    <div class="col-md-9">
                                                        <input type="hidden" formControlName="id">

                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                        </div>
                    </p-card>
                </div>

            </div>

        </div>
    </div>
</form>