import { Component, ElementRef, OnInit } from '@angular/core';
import { ClientsService } from '../../../services/clients.service';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ViewChild } from '@angular/core';
import { Clients } from '../../../models/clients';
import { Category } from '../../../models/category';
import { UntypedFormControl } from '@angular/forms';
import { CategoryService } from '../../../services/categories.service';
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import * as moment from 'moment';
import { FISCAL_CODE_VALIDATOR_REGEX, SEX_LIST, STATE_LIST, BUSINESS_STATE_LIST } from '../../constants/constants';
import { ComuniService } from 'src/app/services/comuni.service';
import { Comuni } from 'src/app/models/comuni';
import {Location} from '@angular/common';
import { AppointmentsService } from 'src/app/services/appointments.service';
import { Appointments } from 'src/app/models/appointments';
import { BillingsService } from 'src/app/services/billings.service';
import { Billings } from 'src/app/models/billings';
import { PersonalDataService } from 'src/app/services/personal_data.service';
import { Personal_data } from 'src/app/models/personal_data';
import { NgxSpinnerService } from "ngx-spinner";
import { Province } from 'src/app/models/province';
import { ProvinceService } from 'src/app/services/province.service';
import { SectorService } from 'src/app/services/sector.service';
import { Sector } from 'src/app/models/sector';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Observable } from 'rxjs';



@Component({
  selector: "app-clients-form",
  templateUrl: "./clients-form.component.html",
})

export class ClientsFormComponent implements OnInit {
  @ViewChild("myInput", { static: false }) myInputVariable: ElementRef;

  pageTitle: string;
  error: string;
  uploadError: string;
  imagePath: any;
  clients: Clients;
  client: Clients;


  clientsList: any = [];
  clientList: Clients;


  categories: any = [];
  category: Category;
  checked: boolean = true;
  selectedValue: string;
  comuni: Comuni;
  provinceList: Province[] = [];
  sectorList: Sector[] = [];

  blogForm: UntypedFormGroup;
  typeList: any[];

  cities: Clients[];
  format1: string = "";
  format2: string = "";
  selectedCity: Clients;
  selectedCategories: Category;
  selectedDate: Date;
  date: Date;
  appointments: any = [];
  is_featured = '0';
  deleteButton: boolean;
  billings: any = [];
  billing: Billings;
  currentUser: any;
  addForm: UntypedFormGroup;
  rows: UntypedFormArray;
  itemForm: UntypedFormGroup;
  skillsForm: UntypedFormGroup;
  skillsValues: any = [];
  stateOptions: any[];
  cols: any[];
  exportColumns: any[];
  _selectedColumns: any[];
  businessStateOptions: any[]; 
  personal_datas: any = [];
  personal_data: Personal_data;
  personName: string;
  mapOptions: any = [];
  infoContent = '';
  markers: any = [];
  zoom: string;
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions: google.maps.LatLngLiteral[] = [];
  previews: string[] = [];
  imageInfos?: Observable<any>;
  marker: any;

  trackByFn(index, item) {
    return item.id;
  }

  center: google.maps.LatLngLiteral = {lat: 44.99266, lng:10.74941};
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow;


  constructor(
    private fb: UntypedFormBuilder,
    private clientsService: ClientsService,
    private messageService: MessageService,
    private personalDataService: PersonalDataService,
    private categoryService: CategoryService,
    private provinceService: ProvinceService,    
    private sectorsService: SectorService,    

    private _location: Location,
    private appointmentsService: AppointmentsService,
    private billingsService: BillingsService,
    private spinner: NgxSpinnerService,

    private confirmationService: ConfirmationService,
    private router: Router,
    private route: ActivatedRoute
  ) {

 
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '[]');
    this.stateOptions = STATE_LIST;
    const userId = this.currentUser.user_id;

  }

  ngOnInit() {
    this.spinner.show();
    this.getProvinceList();
    this.getSectorList();


    const id = this.route.snapshot.paramMap.get("id");
    
    if (id) {
      this.pageTitle = "Modifica Cliente";
      

      this.clientsService.getId(+id).subscribe((res) => {
        this.client = res;

      
        
        this.blogForm.patchValue({
          company: res.company,
          address: res.address,
          province: res.province,
          website: res.website,
          phone: res.phone,
          sector: res.sector,
          position: res.position,
          is_active: res.is_active,
          id: res.id,
        });
        this.getMap(this.client);

      });
    } else {
      this.deleteButton = false;
      this.pageTitle = "Aggiungi Cliente";
    }




    this.blogForm = this.fb.group({
      id: [""],
      company: ["", Validators.required],
      address: ["", Validators.required],
      province: [""],
      website: [""],
      phone: [""],
      position: [""],
      sector: [""],
      is_active: ["0"],
    });


  this.spinner.hide();

  }
  

  getMap(client: Clients) {
    this.mapOptions = {
      streetViewControl: false,
      mapTypeControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scaleControl: false,
      disableDoubleClickZoom: true,
      zoom: 10,
   };

    this.center = {
      lat: parseFloat( this.client.position.split(',')[0] ),
      lng: parseFloat( this.client.position.split(',')[1] ),
    };

    this.marker = {
      lat: parseFloat( this.client.position.split(',')[0] ),
      lng: parseFloat( this.client.position.split(',')[1] ),
      draggable: false,
    };

  }

  getSectorList() {
    this.sectorsService.getAllList().subscribe(data => {
      this.sectorList = data;
    });
    }




  getProvinceList() {
    this.provinceService.getAllList().subscribe(data => {
      this.provinceList = data;
    });
    }

    
  getCategoryItem(category_id: string, id: string) {
    return this.categories.find((item) => item.id === category_id);
  }

  hasNoSelectedAppointments(){
    return this.appointments.filter(appointment => appointment.title).length===0;
  }



  onDelete(id: number, title: string) {

    this.confirmationService.confirm({
      message: 'Sei sicuro di volerlo cancellare',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.clientsService.delete(+id).subscribe(
          res => {
            console.log(res);
            this.messageService.add({ key: 'cancel', severity: 'success', summary: 'Attenzione', detail: 'Cancellazione avvenuto con successo' });
           this._location.back();

          },
          error => {
            this.error = error;
            this.messageService.add({ key: 'cancel', severity: 'warn', summary: 'Attenzione', detail: 'Errore backend' });
          });
      },

    });


  }


  
  onSubmit() {
    const formData = new FormData();
    formData.append("company", this.blogForm.get("company").value);
    formData.append("address", this.blogForm.get("address").value);
    formData.append("province", this.blogForm.get("province").value);
    formData.append("phone", this.blogForm.get("phone").value);
    formData.append("website", this.blogForm.get("website").value);
    formData.append("position", this.blogForm.get("position").value);
    formData.append("sector", this.blogForm.get("sector").value);
    formData.append("is_active", this.blogForm.get("is_active").value);


    

    const id = this.blogForm.get("id").value;
    if (id) {
      console.log(this.blogForm.value)

      this.clientsService.update(formData, +id).subscribe(
        (res) => {

          if (res.status == "error") {
            this.messageService.add({
              key: "myKey1",
              severity: "warn",
              summary: "Informazioni",
              detail: "Errore",
            });

            this.uploadError = res.message;
          } else {
            this.messageService.add({ key: 'myKey1', severity: 'success', summary: 'Attenzione', detail: 'Salvato con sucesso' });
            this.ngOnInit();
            // this._location.back();
          }
        },
        (error) => (this.error = error)
      );
    } else {
      this.clientsService.create(formData).subscribe(
        (res) => {
          if (res.status === "error") {
            this.uploadError = res.message;
          } else {
            this.messageService.add({ key: 'myKey1', severity: 'success', summary: 'Attenzione', detail: 'Salvato con sucesso' });
            this.router.navigate(['/admin/clients']);

          }
        },
        (error) => (this.error = error)
      );
    }
  }
}



export function codFisc(c: UntypedFormControl): { [s: string]: boolean } {
  if (c.value && !c.value.match(FISCAL_CODE_VALIDATOR_REGEX)) {
    return { invalidCF: true };
  }
}
