<p-sidebar [(visible)]="filterSidebar" #op position="right">
    <ng-template pTemplate>
        <div class="p-col-12">

            <div class="p-field p-grid">
                <div class="p-col-12">
    
                    <input type="text" [style]="{ width: '100%' }" pInputText [(ngModel)]="nameFilter" placeholder="Cerca" />
                </div>
            </div>
    
    
     

            <div class="p-field p-grid">
                <div class="p-col-12">
            <p-dropdown [options]="featuredPersons"
            [showClear]="true" placeholder="Scegli" [(ngModel)]="personFilter" [style]="{ width: '100%' }"
            optionLabel="name" optionValue="name">

            </p-dropdown>


                </div>
            </div>

            <div class="p-field p-grid">
                <div class="p-col-6">
                    <button pButton type="reset" class="p-button p-button-secondary btn-block"
                    label="annulla" (click)="reset()">
                </button>
                </div>
    
                <div class="p-col-6">
                    <button pButton type="submit" class="p-button p-button-primary btn-block"
                    label="cerca" (click)="load()">
                </button>
                </div>
    
            </div>
    
        </div>
        
    </ng-template>
</p-sidebar>

<!-- <div *ngFor="let col of cols" [ngSwitch]="col.field">

    <div class="tag" *ngIf="dt.filters[col.field]">{{dt.filters[col.field]? dt.filters[col.field].value : ''}}</div>
</div> -->


<div class="top-main-wrapper">

    <div class="dash-header">
        <div class="dash-title">
    
            <div class="p-field p-grid">
                <div class="p-col-6 p-md-6">
                    <h3>Animazione</h3>
                </div>
                <div class="p-col-6 p-md-6">

                    <button type="button" pButton pRipple icon="pi pi-file-pdf" label="Esporta XLS"
                    (click)="exportExcel()" class="p-button-options p-mr-2 pull-right"
                    tooltipPosition="bottom"></button>
                    
                        <!-- <button type="button" pButton pRipple icon="pi pi-file-pdf" label="Esporta" (click)="exportPdf()"
                        class="p-button-options p-mr-2 pull-right"  tooltipPosition="bottom"></button> -->
                         <button type="text" pButton label="Filter" icon="pi pi-filter"
                        class="p-button p-button-options p-mr-2 pull-right" (click)="filterSidebar = true"></button>

                        <button pButton pRipple icon="pi pi-plus" [routerLink]="['/admin/animations/create']" label="Aggiungi"
                        class="p-button p-button-primary p-mr-2 pull-right"></button>
                </div>
             
            </div>
        </div>
    </div>
</div>

<div class="main-inner">



<div class="p-fluid">
    <div class="p-field p-grid list">
        <div class="p-col-12 p-md-12">
          
                <div class="filter-options" *ngIf="nameFilter">

                    <div class="p-field p-grid">

                        <div class="p-col-12 p-md-12">
                            <div class="item-size pull-left">
                                <span class="filter-tag" *ngIf="nameFilter" >Nome: {{nameFilter}} <span class="close"><i class="pi pi-times"  (click)="reset()"></i></span> </span>
                                <span class="filter-tag" *ngIf="descriptionFilter">Descrizione: {{descriptionFilter}} <span class="close"><i class="pi pi-times" (click)="reset()"></i></span> </span>
                                <span class="filter-tag" *ngIf="codeFilter">Codice: {{codeFilter}} <span class="close"><i class="pi pi-times" (click)="reset()"></i></span> </span>
                                <span class="filter-tag" *ngIf="codeIntFilter">Codice Intì.: {{codeIntFilter}} <span class="close"><i class="pi pi-times" (click)="reset()"></i></span> </span>
                                <span class="filter-tag" *ngIf="brandFilter">Brand: {{brandFilter}} <span class="close"><i class="pi pi-times" (click)="reset()"></i></span> </span>

                            </div>
                        </div>
                    </div>
                </div>

                <div *ngFor=" let animation of animations  | paginate: { itemsPerPage: pageSize, currentPage: page, totalItems: count }; let i = index"></div>


                <p-table #dt [columns]="cols" [value]="animations" [rows]="10" [responsive]="false"  [autoLayout]="true" [paginator]="false">

                    <ng-template pTemplate="header" let-columns>
                        <tr>
                     
                            <th *ngFor="let col of cols">
                                {{col.header}} 
                            </th>
                            <th width="200"></th>
                        </tr>
                       
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr>
                            <td *ngFor="let col of cols">
                                <div *ngIf="col.field == 'image'">
                                    <ngx-progressive-image-loader filter="blur(3px)
                                    drop-shadow(0 0 2.75rem green)">
                    
                    
                    
                                    <div class="image-home">
                                    <img [src]="'https://api-admin.chartacoop.it/media/slideshow/'+ rowData.id + '/' + rowData[col.field].image_name"  class="img-fluid cp object-fit_cover" priority>
                                    </div>
                    
                                    </ngx-progressive-image-loader>
              
                                
                                    </div>

                                <div *ngIf="col.field == 'page_title'">
                                    
                                {{ rowData[col.field] }}<br>
                                <p-tag>id: {{ rowData.id}}</p-tag></div>
                                <div *ngIf="col.field == 'person_id'">{{ rowData[col.field] }}</div>
                                <div *ngIf="col.field == 'area_id'">{{ rowData[col.field] }}</div>
                                <div *ngIf="col.field == 'destination_id'">{{ rowData[col.field] }}</div>
                                <div *ngIf="col.field == 'typology_id'">{{ rowData[col.field] }}</div>

                                <div *ngIf="col.field == 'is_active'">

                                    <span *ngIf="rowData[col.field] == 1"><p-tag severity="success" value="SI"></p-tag>
                                    </span>
                                    <span *ngIf="rowData[col.field] == 0"><p-tag severity="danger"  value="NO"></p-tag></span>
                                   </div>
                            </td>

                            <td class="action">
                                <button pButton pRipple icon="pi pi-trash" class="p-button p-button-danger pull-right p-mr-2"
                                (click)="onDelete(rowData.id)"></button>
                            
                                <button pButton pRipple icon="pi pi-eye" class="p-button p-button-primary pull-right p-mr-2"
                                    (click)="edit(rowData)"></button>
                                    <button pButton pRipple icon="pi pi-pencil" class="p-button p-button-success pull-right  p-mr-2"
                                    [routerLink]="['/admin/animations/edit', rowData.id]"></button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <div class="p-grid">
                    <div class="p-col-12 p-md-4">
                        <p class="p-pt-1">
                            <span class="count-item pull-left">{{pageSize * page - pageSize +1 }} - {{pageSize * page}} di {{count}} risultati</span></p></div>
                    <div class="p-col-12 p-md-8">
                        <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true"
                            (pageChange)="handlePageChange($event)">
                        </pagination-controls>
                    </div>
                </div>
        </div>
    </div>
</div>
</div>
<p-sidebar [(visible)]="productDialog" position="right" [style]="{width:'60%'}">
    <ng-template pTemplate="content">
        <div class="p-col-12 p-md-12">
           

                        <h1>{{animation.page_title}}</h1>
                       <p [innerHTML]="animation.description_1"></p>
                        <hr class="full">
                        <p [innerHTML]="animation.description_2"></p>

                 
                        <hr class="full">

                        <div class="clearfix"></div><br>
                        <button pButton pRipple icon="pi pi-pencil" label="Modifica" class="p-button p-button-primary p-mr-2" [routerLink]="['/admin/animations/edit', animation.id]"></button>
               
              
        </div>
    </ng-template>

</p-sidebar>

{{error}}

<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" #cd>

    <p-footer>
        <button type="button" pButton icon="fa fa-close" class="p-button p-button-danger p-mr-2" label="No" (click)="cd.reject()"></button>
        <button type="button" pButton icon="fa fa-check" class="p-button p-button-success p-mr-2" label="Si" (click)="cd.accept()"></button>
    </p-footer>

</p-confirmDialog>